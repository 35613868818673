body {
    position: relative;
    // font-family: "roboto", sans-serif;
}

.container {
    position: relative;
}

.modal-content {
    max-height: calc(100vh - 3.5rem);
    min-height: 300px;
}

.modal-body {
    overflow: auto;
}

.link {
    color: #075DA8;
    cursor: pointer;
}

.modal-2 {
    z-index: 1052;
}

.backdrop-2,
.backdrop-1 {
    z-index: 1051;
}

.modal-3 {
    z-index: 1053;
}

.backdrop-3 {
    z-index: 1052;
}

.modal-4 {
    z-index: 1054;
}

.backdrop-4 {
    z-index: 1053;
}

/* For sticky header and subtab*/
#main-header {
    position: sticky;
    background-color: white;
    top: 0;
    z-index: 10;
}

.object-detail-tab.main-panel>nav {
    position: sticky;
    top: 81px;
    background-color: #fff;
    z-index: 1;
    border-radius: .25rem .25rem 0 0;
}

#side-nav>nav {
    position: sticky;
    top: 100px !important;
    z-index: 1;
}

.msp-data-grid .grid-table {
    width: auto;
    max-width: 0;
    background: #fff;
}

/*End here*/

.msp-data-grid .grid-table {
    width: auto;
    max-width: 0;
    background: #fff;
}

.msp-data-grid .grid-table td {
    max-width: 0;
    vertical-align: middle;
}

.page-title-info .dropdown-menu {
    max-height: 250px;
    overflow: auto;
}

.msp-data-grid .grid-table th[data-field],
.msp-data-grid .grid-table th[data-field] .d-flex {
    min-width: 100px;
}

.msp-data-grid.mode-edit .grid-table th[data-field],
.msp-data-grid.mode-edit .grid-table th[data-field] .d-flex {
    min-width: 200px;
}

.msp-data-grid .grid-table .head-row th {
    border-top: 2px solid #dddfeb;
    height: 29px;
    position: relative;
}

.grid-table-container {
    position: relative;
    min-height: 300px;
    max-height: calc(100vh - 250px);
    border-left: 1px solid #dddfeb;
}

.object-detail-tab .grid-table-container {
    z-index: 0; // adding QPC-6884
}

/* grid freeze css add  */

.grid-table thead {
    position: sticky;
    top: 0px;
    background-color: #fff;
    z-index: 4;
}

table.grid-table tr th.grid-col-freeze:first-child,
table.grid-table tr td.grid-col-freeze:first-child {
    left: -1px;
}

.grid-table tbody tr.selected td.grid-col-freeze {
    background: rgb(237, 246, 253) !important;
    color: #333;
}

.grid-col-freeze {
    position: sticky !important;
    background-color: #fff !important;
    z-index: 3 !important;
}

.open-row .grid-col-freeze {
    background-color: #075DA8 !important;
}

td.grid-col-freeze::after,
th.grid-col-freeze::after {
    content: '';
    position: absolute;
    right: 0;
    width: 2px;
    height: 100%;
    background: #dddfeb;
    top: 0;
}

td.grid-col-freeze::before,
th.grid-col-freeze::before {
    // According to QPC-7373 done by rishi with shashi chouhan
    content: '';
    position: absolute;
    right: 0;
    left: 0;
    height: 2px;
    background: #dddfeb;
    bottom: -1px;
}

.grid-table-container::before {
    content: "";
    position: absolute;
    border-top: 2px solid #dddfeb;
    border-bottom: 2px solid #dddfeb;
    width: 100%;
    height: 31px;
    top: 0;
    display: none;
}

.msp-data-grid .grid-table .head-row th::before {
    position: absolute;
    content: '';
    top: -1px;
    height: 2px;
    background-color: #dddfeb;
    left: -1px;
    right: 0;
}

.msp-data-grid .grid-table .filter-row th::before {
    position: absolute;
    content: '';
    top: -2px;
    height: 2px;
    background-color: #dddfeb;
    left: -1px;
    right: 0;
}

/* tr.head-row::before {
    content: "";
    position: absolute;
    border-top: 2px solid #dddfeb;
    border-bottom: 2px solid #dddfeb;
    width: 100%;
    height: 31px;
    top: 0;
} */

tr.filter-row::after {
    content: "";
    position: absolute;
    border-bottom: 2px solid #dddfeb;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 50;
}

.grid-table-container{
    
    &::after {
    content: "";
    position: absolute;
    border-bottom: 1px solid #dddfeb;
    width: 100%;
    height: 1px;
    bottom: 0;
    }
    &.after-none::after{
        display:none;
    }
}

.grid-table-container .grid-table {
    position: relative;
    z-index: 5;
}

.grid-table .head-row th,
.grid-table .filter-row th,
.grid-table td {
    padding: 0px;
    position: relative;
}

.grid-table .head-row th.wrap {
    overflow: visible;
}

.grid-table .head-row th>div,
.grid-table .filter-row th>div,
.grid-table td>div {
    padding: 0.2rem 0.5rem;
    display: flex;
    box-sizing: border-box;
}

.msp-data-grid .head-row .grip {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    cursor: col-resize;
    width: 10px;
    display: none;
    z-index: 99;
}

.head-row .d-flex:hover .grip {
    display: block;
    border-right: solid 3px #334554;
}

.grip.active {
    display: block !important;
    opacity: 1 !important;
    border-right: solid 3px #334554;
    left: 0;
    height: 100%;
}

.grip.active::after {
    height: 400px;
    position: absolute;
    right: 0;
    top: 0;
    width: 1px;
    border-right: solid 1px #334554;
    display: block;
    content: " ";
}

.open-row-result {
    max-height: 200px;
    overflow-x: auto;
}

.msp-data-grid .cell-edit {
    display: none;
    width: 100%;
    white-space: normal !important;
}

.msp-data-grid .cell-disp {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
}

.msp-data-grid td.wrap .cell-disp,
.word-wrap {
    white-space: normal;
    overflow-wrap: break-word;
}

.msp-data-grid.mode-edit .hide-edit {
    display: none;
}

.msp-data-grid.mode-edit .cell-edit {
    display: block;
}

.msp-data-grid .grid-table td.text-found {
    background-color: rgba(49, 232, 0, 0.16);
}

.text-found-marketing-contact {
    background-color: rgba(49, 232, 0, 0.16);
}

.head-row th.group-main,
.head-row th.group-child {
    border-bottom-color: #b1c1ce !important;
}

.msp-data-grid .group-child.default {
    display: none;
}

.msp-data-grid .group-child {
    left: 0;
    transition: all 0.5s;
}

.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
    border-color: #64baff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 126, 227, 0.25);
}

.open-row {
    background: #075DA8;
    color: #fff;
    transition: all 0.4s;
}

.open-row .grid-cell .row-icon {
    color: #fff;
    display: block !important;
}

.open-row.selected .grid-cell .row-icon {
    color: #333;
}

.open-row-child {
    transition: all 0.4s;
}

.open-row-child>td {
    background: rgba(0, 126, 227, 0.07);
    padding: 1rem;
    transition: all 0.4s;
    padding: 1rem;
    box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.15);
}

.open-row-child>td table.page-table {
    background: #fff;
    transition: all 0.4s;
}

.open-row-child .page-table thead th,
.open-row-child .page-table thead td {
    color: #075DA8;
}

#page-body .page-content {
    display: none;
}

#page-body .page-content.active {
    display: block;
}

#page-body .page-content .object-name {
    word-break: break-word;
}

#form-diag-cover {
    display: none;
}

#form-diag-cover.active {
    display: block;
    position: fixed;
    left: 20px;
    top: 20px;
    bottom: 20px;
    right: 20px;
    z-index: 101;
    background-color: #fff;
}

#form-diag-overlay {
    display: none;
}

#form-diag-overlay.active {
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 0.5;
    background-color: #222;
    z-index: 100;
}

.msp-auto-complete {
    position: relative;
    width: 150px;
    height: 20px;
    display: inline-block;
}

.msp-auto-complete .list {
    position: absolute;
    top: 20px;
    left: 0;
    min-height: 60px;
    max-height: 400px;
    overflow-y: auto;
}

.msp-multi-dropdown {
    position: relative;
    width: 150px;
    min-height: 20px;
    display: inline-block;
}

.msp-multi-dropdown .list {
    position: absolute;
    top: 20px;
    left: 0;
    min-height: 60px;
    max-height: 300px;
    overflow-y: auto;
    display: none;
}

.msp-multi-dropdown.expand .list {
    display: block;
}

#page-nav {
    white-space: nowrap;
    flex-wrap: nowrap;
    width: 100%;
}

#page-nav .nav-item {
    position: relative;
    padding: 4px 16px;
    z-index: 1;
    color: #fff !important;
    margin-right: 2px;
    transition: 0.05s all;
    background: #075DA8;
    border: 1px solid #075DA8;
    border-bottom: 0 !important;
    border-radius: 10px 10px 0 0 !important;
    cursor: pointer;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
}

#page-nav .nav-item:first-child .tab-close {
    display: none !important;
}

#page-nav .nav-item.active,
#page-nav .nav-item:hover {
    color: #075DA8 !important;
    background: #fff;
    border: 1px solid #075DA8;
    border-bottom: 0 !important;
}

/* Below code to fix lookup PageTitle Tab display issue */
#page-nav .nav-item .lookup-display-record {
    color: #fff;
}

#page-nav .nav-item.active .lookup-display-record,
#page-nav .nav-item:hover .lookup-display-record {
    color: #075DA8 !important;
}

#page-nav .nav-item.active::after,
#page-nav .nav-item:hover::after {
    background: #fff;
    border-bottom: 0;
    margin-bottom: -1px;
}

#page-nav .nav-item {
    transition: all 0.2s;
}

#page-nav .nav-item .tab-close {
    display: inline-block;
    position: absolute !important;
    right: 0;
    top: 0;
    height: 30px;
    padding: 5px;
    box-sizing: border-box;
    display: none;
}

.no-internet {
    position: fixed;
    top: 0;
    width: 100%;
}

#page-nav .nav-item:hover .tab-close {
    display: block;
}

.object-detail #page-nav {
    padding-left: 0;
    margin-left: 0;
}

.object-detail #page-nav .nav-item {
    padding: 5px 16px;
    border-bottom: 1px solid transparent;
}

.object-detail #page-nav .nav-item::after {
    display: none;
}

.object-detail #page-nav .nav-item.active {
    color: #075DA8;
    border-bottom: 1px solid #075DA8;
}

.object-detail #page-nav .nav-item:hover {
    color: #075DA8;
    border-bottom: 1px solid #075DA8;
}

.sidebar-dark .nav-item .nav-link {
    padding: 0.35rem 1rem;
}

.sidebar-dark .nav-item .nav-link i {
    width: 27px;
    height: 30px;
    font-size: 24px;
    line-height: 22px;
    text-align: center;
}

.head-row .sort-field {
    position: relative;
    background: transparent;
    width: 16px;
    height: 16px;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    display: inline-block;
}

.head-row .sort-field:before,
.head-row .sort-field:after {
    position: absolute;
    cursor: pointer;
    opacity: 0.35;
    font-size: 1rem;
    top: 3px;
}

.head-row .sort-field.asc:before,
.head-row .sort-field.desc:after {
    color: #075DA8;
    opacity: 1;
}

.head-row .sort-field:before {
    right: 0em;
    content: "\f0de";
}

.head-row .sort-field:after {
    right: 0em;
    content: "\f0dd";
}

.head-row .expand,
.head-row .text-wrap {
    width: 14px;
    height: 14px;
    text-align: center;
    border-radius: 2px;
    margin-left: 4px;
    font-size: 14px;
    line-height: 13px;
    margin-top: 4px;
    transition: all 0.2s;
    cursor: pointer;
    opacity: 0.35;
    display: inline-block;
}

.head-row .expand:hover,
.head-row .text-wrap:hover {
    opacity: 1;
}

.head-row .field-text {
    flex-grow: 1 !important;
    position: relative;
    text-overflow: ellipsis;
    overflow: hidden;
}

.text-wrap::after {
    display: none;
}

.text-wrap-dropdown {
    min-width: auto !important;
    border: #075DA8 solid 1px !important;
    padding: 0 !important;
}

.text-wrap-dropdown a[data-text="clip"]::before,
.wrap .text-wrap-dropdown a[data-text="wrap"]::before {
    position: absolute;
    left: 10px;
    top: 6px;
    width: 20px;
    height: 20px;
    color: #fff;
    content: "\f00c";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    color: inherit;
    font-size: 10px;
}

.wrap .text-wrap-dropdown a[data-text="clip"]::before {
    display: none;
}

.text-wrap-dropdown a.dropdown-item {
    position: relative;
}

.text-wrap-dropdown a.dropdown-item:hover {
    background: #075DA8;
    color: #fff;
}

.search-in-list,
.text-search-area {
    // QPC-6615 Text Search class added
    max-width: 260px;
    display: inline-block;
    position: relative;
    width: 100%;
}

.reports .report-list .search-in-list {
    max-width: 200px !important;
}

.event-external-link {
    float: right;
    right: 16px;
    top: 16px;
}

.view-calendar {
    z-index: 1;
}

.view-calendar .activity-container .bg-gray-100,
.view-calendar .activity-container.bg-gray-100 {
    background-color: #f8f9fc !important;
    background-image: repeating-linear-gradient(45deg, #eaeff4 0, #eaeff4 2px, transparent 0, transparent 50%);
    background-size: 10px 10px;
    background-color: #f8f9fc;
}

.venue-section{
    .select2-results__option{
        padding:4px 1rem;
        position:relative;
        color: #2e2f37;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
        white-space: nowrap;
        &.select2-results__option--selectable.select2-results__option--highlighted{
            background-color:var(--primary);
            color:white;
        }
        &.select2-results__option--selectable.select2-results__option--selected{
            background:none;
            color:var(--primary);
        }
        &:hover{
            background-color:var(--primary);
            color:white;
        }
        .fa, .fas{
            position:absolute;
            font-size:11px;
        }
        .fa-check{
            left: 2px;
            top: 9px;
        }
        .fa-thumbtack{
            right: 2px;
            top: 9px;
            
        }
    }
    .select2-container--default .select2-selection--single .select2-selection__rendered {
            max-width:170px;
    }
}

/* Mobile version css Start */
@media (max-width:370px) {
    .label-calendar {
        font-size: 12px !important;
    }

    .General-setting .daytiming {
        flex-direction: column;
    }

    .General-setting .daytiming>.col>div {
        margin: 0 !important;
    }

}

@media (max-width: 767px) {

    .search-in-list,
    .text-search-area {
        max-width: 112px;
    }

    .sidebar {
        min-width: 14rem !important;
        width: 14rem !important;
        position: fixed;
        z-index: 10;
        margin-left: -14rem !important;
    }

    .sidebar.slide {
        margin-left: 0;
        z-index: 999;
        min-height: 100%;
        height: 100%;
    }

    .sidebar-dark .nav-item .nav-link i {
        font-size: 1rem !important;
    }

    #main-header {
        position: sticky;
        background-color: white;
        top: 0;
        z-index: 2;
    }

    #searchDropdown {
        display: none;
    }

    .calendar-left-panel {
        margin-left: -218px;
        position: fixed;
        z-index: 1;
        background: #fff;
        transition: all .3s;
        overflow: auto !important;
        padding-right: 1rem !important;
        height: calc(100% - 82px) !important;
    }

    .reports .calendar-left-panel {
        margin-left: -318px !important;
    }

    .calendar-left-panel.active-left-mob {
        margin-left: 0;
        z-index: 998;
    }

    .task-quick-view {
        position: fixed;
        right: 0;
        z-index: 1000;
    }

    .user-menu .dropdown-menu {
        z-index: 1000 !important;
    }

    .modal-dialog {
        min-width: unset !important;
    }

    #page-nav .nav-item {
        padding: 4px 20px 4px 8px !important;
    }

    #page-nav .nav-item:first-child {
        padding: 4px 8px !important;
    }

    #page-nav .nav-item .tab-close {
        background: #075DA8;
        border-radius: 0 10px;
    }

    #page-nav .nav-item:hover .tab-close {
        background: #fff;
        color: #075DA8;
    }

    #page-nav .nav-item.active .tab-close {
        background: #ffffff;
    }

    #page-nav .nav-item .tab-close {
        display: inline-block !important;
    }

    .img-profile-calendar {
        height: 30px !important;
    }

    .sidebar~#content-wrapper .fixed-btn-container,
    .sidebar~.fixed-btn-container {
        left: 0 !important;
    }


    /*For grid table*/

    /* Force table to not be like tables anymore */
    .grid-table-container {
        max-height: calc(100vh - 225px);
    }

    .msp-data-grid .grid-table {
        width: auto;
        max-width: unset;
        background: #fff;
        display: block !important;
        z-index: 0;
        height: 100%;
        max-height: 100vh;
    }

    .msp-data-grid .grid-table,
    .msp-data-grid .grid-table thead,
    .msp-data-grid .grid-table tbody,
    .msp-data-grid .grid-table th,
    .msp-data-grid .grid-table td,
    .msp-data-grid .grid-table tr {
        display: block;
    }

    /* Hide table headers (but not display: none;, for accessibility) */
    .msp-data-grid .grid-table tr.head-row {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }

    .msp-data-grid .grid-table .filter-row {
        position: relative;
        width: 100%;
        z-index: 10;
        height: 100%;
        overflow: auto;
        transition: all .3s;
        border-radius: 0 !important;
        border: 0 !important;
        display: none;
        border-radius: .5rem;
    }

    .msp-data-grid .grid-table .filter-row.active {
        display: block;
    }

    /* .msp-data-grid .grid-table tr:nth-child(odd){
        background: #f7f9fc; 
    } */
    .msp-data-grid .grid-table tr {
        border: 1px solid rgba(5, 93, 168, 16%);
        background-color: #f7f9fc;
        margin-bottom: 1rem;
        border-radius: .5rem;
    }

    .msp-data-grid .grid-table td,
    .msp-data-grid .grid-table .filter-row th {
        /* Behave  like a "row" */
        border: none;
        border-bottom: 1px solid #e3e6f0 !important;
        position: relative;
        padding-left: 50%;
        min-height: 32px;
        max-width: unset;
    }

    .msp-data-grid .grid-table td:last-child {
        border-bottom: none !important;
    }

    .msp-data-grid .grid-table tr td:first-child,
    #secondry-navs {
        display: none;
    }

    .user-profile-detail .msp-data-grid .grid-table tr td:first-child {
        display: block;
    }

    .msp-data-grid .grid-table td::before,
    .msp-data-grid .grid-table .filter-row th::before {
        content: attr(data-label);
        /* Now like a table header */
        position: absolute;
        /* Top/left values mimic padding */
        top: 4px;
        left: 6px;
        width: 45%;
        padding-right: 10px;
        white-space: nowrap;
        color: #075DA8;
        font-weight: 500;
        display: block !important;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .close-mob-filter {
        position: absolute;
        right: 4px;
        top: 0px;
        z-index: 4;
    }

    .msp-data-grid .grid-table .filter-row th::before {
        content: attr(data-label);
        height: auto;
        top: 6px !important;
    }

    .grid-table tbody tr:hover {
        box-shadow: none !important;
    }

    .grid-table-container {
        min-height: unset;
    }

    .grid-table-container::before {
        display: none;
    }

    .grid-col-freeze {
        position: relative !important;
        background-color: transparent !important;
        z-index: 3 !important;
        left: unset !important;
    }

    .gird-button-list .btnFilter,
    .gird-button-list .btnEdit,
    .gird-button-list .search-in-list,
    //.gird-button-list .text-search-area,
    .gird-button-list .btnDelete,
    //.gird-button-list .btnSearch,
    .gird-button-list .btn.move-btn,
    .gird-button-list .btn.copy-btn,
    .gird-button-list .btn.copyTo-btn {
        display: none;
    }

    .gird-button-list .show-list-view {
        display: inline-block;
    }

    .gird-button-list .select-venue.d-inline-block {
        display: none !important;
    }

    .page-top-button .btn,
    .DashboardList .page-top-button .btn.btn-add-object,
    .TaskListGrid .btnListView,
    .page-top-button .btn.btn-edit-object.btnCloneProfile,
    .reports.msp-data-grid .page-top-button .btn.btn-add-object,
    .page-top-button .btn[data-fn="_campaign.InitCampaignAttribute()"],
    [data-uid*="WorkflowRulesDetail"] .page-top-button .btn.btn-edit-object,
    [data-uid*="UserDetail"] .page-top-button .btn.btn-edit-object {
        display: none !important;
    }

    .page-top-button .btn.btn-add-object,
    .page-top-button .btn.close-task-quick-view,
    .page-top-button .btn.btn-edit-object,
    .page-top-button .btn.btnRefresh,
    .page-top-button [data-restriction="Create"],
    .page-top-button .upload-button,
    .page-top-button .newfolder,
    .page-top-button .btn-sm.btn-primary.dropdown-toggle,
    .page-top-button .btn-addSharing,
    [data-uid^="ActivityDetail"] [data-fn="_calendar.ActivityDetailEdit(self)"],
    .page-top-button .btn[data-fn^="_eventsCalendar.EditEventActivityFromOtherSource(self)"],
    [data-uid*="WorkflowRulesDetail"] .page-top-button .btn.btn-deactivate-rule,
    [data-uid*="WorkflowRulesDetail"] .page-top-button .btn.btn-activate-rule,
    .page-top-button .btn.create-new-action,
    .page-top-button .btn.btnBlockUser.active,
    .page-top-button .btn.btnUnblockUser.active,
    .page-top-button .btn.btn-outline-primary[data-fn="_automation.OpenWorkflowActionForm(self)"] {
        display: inline-block !important;
    }

    .page-top-button .btn.btn-deactivate-rule[disabled="disabled"],
    [data-uid*="WorkflowRulesDetail"] .page-top-button .btn.btn-activate-rule[disabled="disabled"] {
        display: none !important;
    }

    td.grid-col-freeze::after,
    th.grid-col-freeze::after,
    .msp-data-grid .grid-table .filter-row th::before {
        background: none;
        top: 0;
    }

    .grid-table-container {
        border: none;
    }

    .grid-table tbody tr:hover .row-icon,
    .grid-table tbody tr:hover td[data-label="ID"] .row-icon {
        display: none !important;
    }

    tr.filter-row::after {
        display: none;
    }

    /*Detail page css*/

    .object-detail .nav-tabs {
        white-space: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
        flex-wrap: unset;
    }

    .swal-modal {
        width: calc(100% - 20px) !important;
    }

    .grid-table-container+nav.hide-edit {
        position: fixed;
        bottom: 0;
        padding: 1rem 0.5rem;
        left: 0;
        background: #fff;
        right: 0;
        overflow: auto;
    }

    .grid-table-container+nav.hide-edit .pagination {
        margin-bottom: 0;
    }

    .grid-table-container::after {
        display: none;
    }

    .msp-data-grid.mb-5 {
        margin-bottom: 0 !important;
    }

    .mt-3.object-detail-tab.main-panel {
        margin-top: 0 !important;
    }

    .AdminTagList.msp-data-grid .grid-single-deleteBtn {
        display: none;
    }

    .tab-pane>.table-sec .card-header {
        padding-top: 0;
    }

    #side-nav {
        flex-basis: auto;
        max-width: 100% !important;
        top: 80px;
        z-index: 1;
        position: sticky;
        padding: 10px;
        background: #fff;
    }

    #side-nav .nav {
        max-width: 100% !important;
        display: block !important;
        padding-bottom: 0 !important;
        overflow: auto;
        white-space: nowrap;
        margin-top: 0 !important;
    }

    #side-nav .nav a,
    #side-nav .nav .nav-link {
        display: inline-block !important;
    }

    #side-content .blankheight {
        min-height: calc(100vh - 330px) !important;
    }

    .General-setting>.col.col-auto,
    .setting-tab>.col.col-auto,
    .event-setting>.col.col-auto,
    .marketing-setting>.col.col-auto,
    .pmsconfig>.col.col-auto,
    .terms-condition>.col.col-auto {
        width: 100% !important;
    }

    .General-setting>.col.col-auto[id^=item-]:nth-child(2),
    .General-setting>.col.col-auto[id^=item-]:nth-child(3),
    .General-setting>.col.col-auto[id^=item-]:nth-child(4) {
        margin-top: -110px;
        padding-top: 110px;
    }

    .control-panel .box,
    .integration-plugins .box {
        max-width: 100% !important;
    }

    .user-working-days .starttime,
    .user-working-days .endtime {
        max-width: 105px !important;
    }

    .help-left-panel {
        display: none;
    }

    .apdCountry {
        max-width: 150px;
    }

    .campaign-container .campaign {
        display: block !important;
        white-space: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
    }

    .campaign-container .campaign li {
        display: inline-block !important;
    }

    .upload-section {
        max-width: 300px;
        left: calc(100% - 320px) !important;
    }

    [data-uid*="ProfilePictureEdit"] .form-group .row,
    [data-uid*="EventUploadListPicture"] .form-group .row,
    [data-uid*="EventUploadDetailPicture"] .form-group .row {
        display: block !important;
    }

    .single-day-activity {
        z-index: unset !important;
    }

    .cal-body .col {
        margin: 2px;
    }

    .General-setting .active-days {
        border-radius: 5% !important;
    }

    .General-setting,
    .employee-setting,
    .setting-tab,
    .unsubscribePageTemplate,
    .marketing-setting,
    .terms-condition {
        position: relative;
        z-index: 0;
    }

    .employee-setting>.col.col-auto {
        width: 100% !important;
    }

    .license-sec {
        top: unset;
        z-index: 0 !important;
    }

    .UserList .license-sec {
        display: none;
    }

    [data-uid="ControlPanel"] [page-name="ObjectSchemaList"] {
        display: none;
    }

    /*Secondary Nabvar*/
    .secondry-nav-items #secondry-collapsable-navs .nav-item .nav-link {
        padding: 0.35rem 0.5rem;
        display: flex !important;
        align-items: center;
    }

    .sidebar-dark .secondry-nav-items #secondry-collapsable-navs .nav-item span {
        display: inline-block !important;
        color: #000;
        white-space: nowrap;
        overflow: hidden;
        /* max-width: 100px; */
        width: calc(100% - 27px);
        text-overflow: ellipsis;
    }

    .General-setting .daytiming .input-group-prepend .btn {
        z-index: 0;
    }

    /*Dashboard css*/
    .dashboard-grid {
        padding: 0 !important;
    }

    .dashboard-grid .DashboardLoading {
        z-index: 0;
    }

    /* .grid-stack-item{
        height: auto !important;
    }  */
    .multiselect-container.dropdown-menu {
        max-width: calc(100vw - 60px) !important;
    }

    .img-profile-calender-initials {
        height: 30px !important;
        width: 30px !important;
        font-size: 14px !important;
        padding-top: 4px !important;
    }

    .view-calendar .row .col,
    .uploaded-files li.list-group-item {
        z-index: 0;
    }

    .uploaded-files li.list-group-item {
        z-index: 0 !important;
    }

    .campaign-container.p-3 {
        padding: 0 !important;
    }

    .event-external-link {
        float: unset !important;
        right: unset !important;
        top: unset !important;
    }

    [data-field="ExternalRegistrationLink"]>label.small {
        float: unset !important;
    }

    [data-field="ExternalRegistrationLink"]>div.text-gray-900 {
        float: unset !important;
        margin-top: unset !important;
        margin-right: unset !important;
    }

    [data-field="ExternalRegistrationLinkBox"]>label.small,
    .btnEditUnsubscribePage,
    .dropdown-item.btnStoreMenu {
        display: none !important;
    }

    .folder-owner.row b {
        font-size: .85rem;
        font-weight: normal;
    }

    .highcharts-exporting-group,
    .main-drive-spacebar {
        display: none !important;
    }

    .main-drive .main-drive-list tr td:nth-child(2)::before {
        content: 'Name';
    }

    .main-drive .main-drive-list tr td:nth-child(3)::before {
        content: 'Size';
    }

    .main-drive .main-drive-list tr td:nth-child(4)::before {
        content: 'Date';
    }

    .main-drive .main-drive-list tr td:nth-child(5)::before {
        content: 'Owner';
    }

    .main-drive .main-drive-list tr td:first-child,
    [data-uid="UserList"] .msp-data-grid .grid-table tr td:first-child {
        display: block !important;
    }


}

@media (hover: hover) and (pointer: fine) {

    .lblChk:not(.disabled-checkbox):hover input~.checkmark {
        background-color: #075DA8;
        border-color: rgba(0, 125, 227, 0.205);
    }

    .object-detail-tab .nav-tabs .nav-link:hover {
        color: #075DA8;
        border: 0;
        border-bottom: 2px solid #075DA8;
        cursor: pointer;
    }
}

/* Mobile version css end */
[data-uid="EventDetail"] [data-group="AddressInformation"] .msp-popup {
    max-width: 100%;
}

[data-group="AddressInformation"] .msp-popup .item {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

[data-uid*="ProfilePictureEdit"] .form-group .row,
[data-uid*="EventUploadListPicture"] .form-group .row,
[data-uid*="EventUploadDetailPicture"] .form-group .row {
    display: block;
}

.msp-data-grid .grid-table td .data-lable-mob {
    display: none;
}

.search-in-list input,
.text-search-area input {
    border-color: #858796;
}

.sidebar {
    transition: 0.35s all;
    margin-left: 0rem;
    overflow: hidden;
}

.slide {
    margin-left: 0 !important;
    width: 14rem !important;
}

.table-filter .edit-filter {
    cursor: pointer;
    margin-left: 10px;
    padding: 2px 12px 2px 3px;
    box-sizing: border-box;
    border-radius: 2px;
}

.table-filter .dropdown-toggle::after {
    font-size: 20px;
    vertical-align: 0.1em;
}

.head-row {
    background: #eaecf4;
}

.show-map {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: none;
    transition: all 0.3s;
}

.close-map {
    right: 0;
    margin-right: 10px;
    width: 40px;
    bottom: 105px;
    height: 40px;
    font-size: 20px;
    line-height: 30px;
    position: absolute;
    border-radius: 2px 2px 0 0;
}

.grid-filter-sec {
    display: none;
    transition: all 0.3s;
}

.calendar-left-panel {
    padding: 1rem 0.5rem;
    height: calc(100vh - 82px);
    overflow: hidden;
    padding-right: calc(0.5rem + 6px);
}

.calendar-left-panel.shadow {
    box-shadow: 0 0.15rem 0.75rem 0 rgba(58, 59, 69, 0.15) !important;
}

.calendar-left-panel:hover {
    overflow: scroll;
    padding-right: calc(0.5rem);
}

.custom-scrollbar::-webkit-scrollbar,
.calendar-left-panel::-webkit-scrollbar {
    width: 6px;
}

.custom-scrollbar.scroll-horizontal::-webkit-scrollbar {
    width: unset;
    height: 9px;
}

.custom-scrollbar::-webkit-scrollbar-track,
.calendar-left-panel::-webkit-scrollbar-track {
    background: #fff;
}

.custom-scrollbar::-webkit-scrollbar-thumb,
.calendar-left-panel::-webkit-scrollbar-thumb {
    background: #c1c1c1;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover,
.calendar-left-panel::-webkit-scrollbar-thumb:hover {
    background: #888;
}

.calendar-left-panel .staff-filter-menu .multiselect-container.dropdown-menu {
    max-width: fit-content;
}

[data-uid="Calendar"] {
    margin-bottom: -3rem;
}

.cal-body .col {
    border: none !important;
    font-size: 75%;
    font-weight: 600;
    padding: 6px 0px;
    cursor: pointer;
}

.view-calendar .week-view-head {
    font-size: 75%;
    text-transform: uppercase;
    font-weight: 600;
}

.view-calendar .day-grid .u {
    border-bottom: dotted 0.75px #e3e6f0;
    height: 20px;
}

.view-calendar .day-grid .l {
    border-bottom: solid 0.75px #e3e6f0;
    height: 20px;
}

.view-calendar .row .col {
    padding: 0;
}

.view-calendar .all-day .col {
    min-height: 20px;
}

.view-calendar .time-sheet {
    max-width: 10px;
    margin-left: 70px;
}

.view-calendar .time-sheet .p {
    border-bottom: solid 1px #e3e6f0;
    position: relative;
    height: 40px;
}

.view-calendar .time-sheet .c {
    font-size: 80%;
    position: absolute;
    right: 15px;
    top: -10px;
    white-space: nowrap;
}

.view-calendar .dummy {
    max-width: 80px;
}

.view-calendar .view-scroll {
    overflow-y: scroll;
    overflow-x: hidden;
}

.view-calendar h4 {
    width: 2.25rem;
    padding: 6px;
    margin: auto;
}

.attendee-type.contact,
.attendee-type.user {
    position: relative;
}

.lookup-item:hover .attendee-type.contact::before,
.lookup-item:hover .attendee-type.user::before,
.msp-multilookup .item:hover .attendee-type.contact::before,
.msp-multilookup .item:hover .attendee-type.user::before,
.msp-multilookup .item-tag:hover .attendee-type.contact::before,
.msp-multilookup .item-tag:hover .attendee-type.user::before {
    color: #fff;
}

.attendee-type.contact::before,
.attendee-type.user::before {
    position: absolute;
    font-family: "Font Awesome 5 Free";
    left: 0;
    color: #075DA8;
    transition: all 0.3s;
}

.msp-ctrl-lookup .btn-outline-secondary {
    padding-right: 0;
    position: relative;
    margin: 0;
    width: calc(100%);
    text-align: left;
    padding: 0;
    border: 0 !important;
}

.secondary-label {
    display: none;
}

.msp-ctrl-multilookup {
    min-height: 1.85rem;
    height: auto !important;
}

.msp-ctrl-multilookup input {
    display: none;
    margin: 0;
    border: 0;
    outline: 0;
    width: 100%;
}

.msp-ctrl-multilookup.focus-ctrl {
    border: 1px solid #64baff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 126, 227, 0.25);
}

.msp-ctrl-multilookup.focus-ctrl input {
    height: 1.25rem;
    display: block;
}

.msp-ctrl-multilookup .lookup-item {
    max-width: 100%;
    display: inline-flex;
    align-items: baseline;
}

.msp-ctrl-multilookup .lookup-item>span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.filter-row .msp-ctrl-lookup .btn-outline-secondary {
    padding: 0;
    border: 0 !important;
    text-align: left;
    margin: 0;
}

.filter-row .msp-ctrl-lookup .btn-outline-secondary:hover {
    background: #fff;
    color: #858796;
}

.filter-row .msp-ctrl-lookup .btn-outline-secondary .fa-times {
    top: 2px;
    right: 0;
}

.msp-ctrl-lookup-multi .btn-outline-secondary {
    padding-right: 1.25rem;
    position: relative;
    margin: 0.125rem;
    display: inline-block;
}

.unread-icon {
    height: 0.5rem;
    width: 0.5rem;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.msp-ctrl-lookup .fa-times,
.msp-ctrl-lookup-multi .fa-times,
.div-file-uploading .fa-times {
    position: absolute;
    right: 0rem;
    top: 2px;
}

.div-file-uploading .fa-times {
    top: 4px;
    margin-right: 4px;
    color: #ff0000;
    cursor: pointer;
}

.msp-ctrl-lookup .btn-outline-secondary:hover {
    color: #333333 !important;
    background: #fff !important;
}

.msp-popup {
    padding: 0.25rem 0.5rem;
}

.msp-popup .item,
.msp-popup .item-tag,
.msp-popup .add-new {
    padding: 0.25rem 0.5rem;
    cursor: pointer;
}

.msp-popup .item:hover,
.msp-popup .item-tag:hover,
.item-selected {
    background: #858796;
    color: #fff;
}

.msp-popup .no-data-found {
    padding: 0.25rem 0.5rem;
}

.current-day {
    background: #075DA8;
    border-radius: 50%;
    color: #fff;
}

.current-day:hover {
    background-color: #0069bd !important;
}

.current-day.item-selected {
    background: #075DA8;
}

.msp-popup .secondary-label {
    display: block;
    font-size: 0.75rem;
    color: #888;
}

.msp-popup .item:hover .secondary-label,
.msp-popup .item-tag:hover .secondary-label,
.item-selected .secondary-label {
    color: rgba(255, 255, 255, 0.6);
}

.msp-ctrl-lookup-multi p {
    outline: none;
    padding: 2px 4px;
    margin: 1px 0;
    height: 1.5rem;
    width: calc(100% - 0.65rem);
}

button.btn.menu-pin {
    position: absolute;
    right: 0;
    top: 0;
    color: rgba(255, 255, 255, .7);
}

.msp-ctrl-file::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    display: block;
    padding: 0.25rem 0.5rem;
    line-height: 1.5;
    color: #6e707e;
    content: "Browse";
    background-color: #eaecf4;
    border-left: 1px solid #d1d3e2;
    border-radius: 0 0.2rem 0.2rem 0;
}

.msp-ctrl {
    position: relative;
}

.msp-ctrl .row {
    margin-top: -0.2rem;
    padding: 0;
    height: calc(100% + 0.3rem);
}

.msp-ctrl input[type="text"] {
    border: 0;
    width: 100%;
    height: 100%;
}

.msp-ctrl select {
    border: 0;
    width: 100%;
    height: 100%;
    outline: none;
}

.msp-ctrl-date::before {
    position: absolute;
    font-family: "Font Awesome 5 Free";
    content: "\f073";
    left: 0.25rem;
    top: 0.215rem;
}

.msp-ctrl-fromdate,
.msp-ctrl-todate {
    max-width: 94px
}

.msp-ctrl-time::before {
    position: absolute;
    font-family: "Font Awesome 5 Free";
    content: "\f017";
    left: 0.25rem;
    top: 0.215rem;
}

.msp-ctrl-date input,
.msp-ctrl-time input {
    position: absolute;
    left: 1.5rem;
    top: 1px;
    height: calc(100% - 2px) !important;
    width: calc(100% - 1.75rem) !important;
}

.msp-ctrl[data-required],
.mandate-field {
    border-left: 0.1rem solid red !important;
}

.msp-ctrl[data-ui="image"][data-required] {
    border-left: 0.1rem solid red !important;
    border-radius: 0.35rem !important;
}

.msp-ctrl-image-edit[disabled],
.msp-ctrl-image-remove[disabled],
.div-file-uploading a[disabled] {
    pointer-events: none;
}

.disabled-ctrl {
    opacity: 0.65;
}

.btn-light {
    background: #d1d3e2;
    border-color: #d1d3e2;
}

.mode-edit .msp-ctrl-lookup .btn-outline-secondary {
    border: 0 !important;
    padding: 0 !important;
    text-align: left;
    margin: 0;
}

.mode-edit .msp-ctrl-lookup .btn-outline-secondary:hover {
    background: #fff;
    color: #858796;
}

.mode-edit .msp-ctrl-lookup .btn-outline-secondary .fa-times {
    right: 0;
    top: 2px;
}

.has-error .form-control {
    border-color: red;
}

.has-error .vld-message {
    margin-top: 2px;
    font-size: 13px;
    color: red;
}

.cursor-move {
    cursor: move;
}

.cursor-pointer {
    cursor: pointer;
}

.align-items-stretch {
    align-items: stretch;
}

.grid-table tbody tr:hover {
    box-shadow: inset 1px 0 0 #bfceec, inset -1px 0 0 #dfe7f7, 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
    z-index: 1;
}

.grid-table tbody tr.selected {
    background: rgba(0, 126, 227, 0.07);
    color: #333;
}

.lblChk {
    display: inline-block;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin: 0;
    padding: 0;
    min-width: 16px;
    min-height: 16px;
    margin-top: 1px;
}

.parent-lblChk .lblChk {
    display: block;
    margin-top: 8px;
}


/* Hide the browser's default checkbox */

.lblChk input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}


/* Create a custom checkbox */

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: #fff;
    border: #adb5bd solid 1px;
    border-radius: 4px;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

[disabled] {
    color: #858796;
}

.disabled label {
    color: #ddd;
    text-decoration: none;
}

.disabled .checkmark {
    background: #eee;
}

.disabled a:hover {
    text-decoration: none;
}

.disabled .checkmark:hover,
.disabled .checkbox:hover .checkmark {
    background-color: #eee;
}

.disabled .lblChk:not(.disabled-checkbox):hover input~.checkmark {
    background-color: #eee;
}


/* On mouse-over, add a grey background color */



.lblChk.disabled-checkbox input~.checkmark {
    box-shadow: 0 0 0 3px rgba(63, 63, 63, 0.205);
}


/* When the checkbox is checked, add a blue background */

.lblChk input:checked~.checkmark {
    background-color: #075DA8;
    border-color: rgba(0, 125, 227, 0.205);
    box-shadow: 0 0 0 3px rgba(0, 125, 227, 0.205);
}


/* Create the checkmark/indicator (hidden when not checked) */

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}


/* Show the checkmark when checked */

.lblChk input:checked~.checkmark:after {
    display: block !important;
}

.lblChk input:checked~.radioCheck:after {
    display: block !important;
    background: #fff;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    top: 4px;
    left: 4px;
}


/* Style the checkmark/indicator */

.lblChk .checkmark:after {
    left: 5px;
    top: 2px;
    width: 5px;
    height: 8px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.object-detail #page-nav {
    padding-left: 0;
}

.object-detail #page-nav .nav-item::after {
    display: none;
}

.object-detail #page-nav .nav-item {
    padding: 4px 16px;
    margin: 0;
}

.object-detail #page-nav .nav-item.active,
.object-detail #page-nav .nav-item:hover {
    color: #075DA8;
    border-bottom: 2px solid #075DA8;
}

.object-detail .nav-tabs {
    border-bottom-color: #075DA8;
}


/* Remove default bullets */


/* Remove margins and padding from the parent ul */

#branchView {
    margin: 0;
    padding: 0;
    list-style: none;
    padding-left: 16px;
}

#branchView ul {
    position: relative;
    margin-left: 10px;
}

#branchView li {
    position: relative;
}

#branchView ul li::before {
    content: "";
    display: block;
    width: 10px;
    height: 0;
    border-top: 1px solid;
    margin-top: -1px;
    position: absolute;
    top: 10px;
    left: 0px;
    margin-left: -10px;
}

#branchView ul::before {
    content: "";
    display: block;
    width: 0;
    position: absolute;
    top: -5px;
    bottom: 0;
    left: 5px;
    border-left: 1px solid;
}

#branchView .nested {
    margin: 0;
    padding: 0;
    list-style: none;
    padding-left: 16px;
}


/* Style the caret/arrow */

#branchView .caret {
    cursor: pointer;
    user-select: none;
    /* Prevent text selection */
}


/* Create the caret/arrow with a unicode, and style it */

#branchView .caret::before {
    content: "\f0fe";
    color: black;
    display: inline-block;
    margin-right: 6px;
    font-family: "Font Awesome 5 Free";
}


/* Rotate the caret/arrow icon when clicked on (using JavaScript) */

#branchView .caret-down::before {
    content: "\f146";
    font-family: "Font Awesome 5 Free";
}


/* Hide the nested list */

#branchView .nested {
    display: none;
}


/* Show the nested list when the user clicks on the caret/arrow (with JavaScript) */

#branchView .active {
    display: block;
}

#branchView .caret.caret-down {
    color: #075DA8;
}

#branchView .caret.caret-down::before {
    color: #075DA8;
}

.object-detail-right .collapsed i {
    transform: rotate(180deg);
    transition: all 0.35s;
}

.object-detail-right .card:first-child {
    margin-top: 0 !important;
}

.simple-list ul {
    list-style: none;
    padding-left: 0px;
    margin: 0;
}

.simple-list ul li a {
    padding: 0.6rem 1rem;
    display: block;
    border-bottom: 1px solid #eee;
    text-decoration: none;
    transition: all 0.2s;
}

.simple-list ul li a:hover {
    background: #075DA8;
    color: #fff;
}

.changed .msp-ctrl,
.changed input,
.changed select,
.changed textarea,
.changed .msp-ctrl-lookup,
.changed.grid-name,
.field-block .card.changed,
.field-item.changed {
    background: #c1dcf2;
}

.msp-ctrl-lookup .lookup-item {
    display: flex;
}

.msp-ctrl-lookup .lookup-item>span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: calc(100% - 10px);
}

.cell-edit i.fa-lock {
    font-size: 10px;
    position: absolute;
    right: 10px;
    top: 30%;
}

#GridSettingModal .ui-sortable-handle {
    width: 100% !important;
}

.grid-cell {
    position: relative;
    justify-content: space-between;
    display: flex;
    margin: auto;
}

.grid-cell .row-icon {
    display: none !important;
    position: absolute;
    right: 0px;
    top: 2px;
    width: 14px;
    height: 14px;
    font-size: 14px;
    text-align: center;
    color: #075DA8;
    border-radius: 2px;
    box-sizing: border-box;
    padding-top: 3px;
    cursor: pointer;
    animation: arrowBranch 0.3s ease-in;
}

@keyframes arrowBranch {
    0% {
        top: 0;
    }

    50% {
        top: 5px;
    }

    100% {
        top: 0;
    }
}

@keyframes swing {
    0% {
        -webkit-transform: rotateZ(-5deg);
        transform: rotateZ(-5deg);
    }

    50% {
        -webkit-transform: rotateZ(5deg);
        transform: rotateZ(5deg);
    }

    100% {
        transform: rotateZ(-5deg);
        -webkit-transform: rotateZ(-5deg);
    }
}

#notificationDropdown i.bell-swing {
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    animation: road-animates 1s linear infinite;
    -webkit-animation: swing 1s linear infinite;
    -moz-animation: swing 1s linear infinite;
    -o-animation: swing 1s linear infinite;
    transform-origin: center top;
    -moz-transform-origin: center top;
    -webkit-transform-origin: center top;
    color: #858796;
}

#notificationDropdown .badge {
    font-size: 85%;
}

.grid-cell .row-icon::before {
    top: 3px;
}

.grid-table tbody tr:hover .row-icon {
    display: inline-block !important;
}

.mode-edit .grid-table tbody tr:hover .row-icon {
    display: none !important;
}

@keyframes leftRight {
    0% {
        left: -100px;
        opacity: 0;
        background: white;
    }

    75% {
        background: rgb(232, 235, 255);
    }

    100% {
        left: 0;
        opacity: 1;
    }
}

.input-group-prepend .btn-outline-primary,
.input-group-append .btn-outline-primary {
    border-color: #d1d3e2;
    font-size: 0.85rem !important;
    line-height: 1 !important;
}

.input-group-prepend {
    margin-right: 0;
}

.input-group-prepend .btn {
    border-right: 0;
}

.page-form .modal-body div:first-child h6 {
    margin-top: 0 !important;
}

.object-drive-tab-details {
    min-width: 282px;
    max-width: 282px;
    position: relative;
    margin-right: 16px;
}

#myTabContent .dropdown p {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
}

.object-detail-tab .nav-tabs .nav-link {
    color: #333;
    border: 0;
    border-bottom: 2px solid transparent;
}

.object-detail-tab .nav-tabs .nav-link.active,
.object-detail-tab .nav-tabs .nav-item.show .nav-link {
    color: #075DA8;
    border: 0;
    border-bottom: 2px solid #075DA8;
    cursor: pointer;
}

.object-detail-tab .rounded-circle {
    width: 30px;
    height: 30px;
    line-height: 30px;
    box-sizing: border-box;
    padding: 0;
}

.pr-15 {
    padding-right: 15px;
}


/*calendar css*/

.cal-activity {
    color: white;
    position: absolute !important;
}

.month-activity-container {
    position: relative;
}

.right-clicked-activity {
    background: rgba(0, 126, 227, 1) !important;
    transition: all 0.2s;
    color: #fff !important;
}

.allday-high-activity,
.allday-low-activity {
    position: absolute;
    right: 4px;
    font-size: 16px;
    line-height: 1;
    top: 1px;
}


/* .day-cal-activity[data-priority="high"]::after, .single-day-activity.cal-activity[data-priority="high"]::after, .month-cal-activity[data-priority="high"]::after {
  content: "!";
  position: absolute;
  right: 5px;
  font-size: 16px;
  top: -2px !important;
  color: red;
  font-weight: bold;
} */

.day-cal-activity.pending[data-priority="high"]::after,
.cal-activity.pending[data-priority="high"]::after,
.month-cal-activity.pending[data-priority="high"]::after {
    color: #fff;
}

.single-day-activity,
.month-cal-activity,
.meeting {
    background-color: #d2effd;
    padding: 0 6px;
    color: #2b77ad;
    font-weight: 600;
    box-sizing: border-box;
    border: 1px solid #fff;
    opacity: 1;
    text-align: left;
    cursor: grab;
    border-left: solid 2px #2b77ad !important;
    border-radius:2px;
}

.activity-context-menu {
    width: 16px;
    height: 16px;
    display: inline-block;
    font-size: 9px;
    font-weight: bold;
    background: #075DA8;
    line-height: 16px;
    border-radius: 50%;
    text-align: center;
    color: #fff;
    margin-right: 2px;
    position: relative;
    top:0;
}

.right-clicked-activity .activity-context-menu,
[data-uid="Calendar"] .dropdown-item:active .activity-context-menu {
    color: #075DA8;
    background: #fff;
}

.month-cal-activity.middle-split-activity::before,
.month-cal-activity.last-split-activity::before {
    border-right-color: #d2effd !important;
}

.month-cal-activity.first-split-activity::after,
.month-cal-activity.middle-split-activity::after {
    border-left-color: #d2effd !important;
}

.month-cal-activity.middle-split-activity[data-activitytype="Call"]::before,
.month-cal-activity.last-split-activity[data-activitytype="Call"]::before {
    border-right-color: #fff4cd !important;
}

.month-cal-activity.first-split-activity[data-activitytype="Call"]::after,
.month-cal-activity.middle-split-activity[data-activitytype="Call"]::after {
    border-left-color: #fff4cd !important;
}

.single-day-activity:active,
.month-cal-activity:active {
    cursor: grabbing;
    background: #075DA8 !important;
    color: #fff !important;
}


/* .month-cal-activity[data-activitytype="Call"], .single-day-activity[data-activitytype="Call"], .call {
  background: #fff4cd;
  border: 1px solid #fff;
  border-left: #bb8a20 2px solid;
  color: #bb8a20;
  font-weight: 600;
  opacity: .8;
} */

.meeting,
.call {
    box-shadow: none;
    cursor: default;
}

.other-user-single-day-activity {
    background-color: rgb(137, 195, 243);
    padding-left: 2px;
    box-shadow: 1px 1px 5px #eee;
}

.cal-activity.pending,
.month-cal-activity.pending {
    padding: 0px;
    padding-left: 0;
    /* border-left: solid 2px #ff6000!important; */
    /*animation: blinkingMeetingBackground 1s infinite;*/
    position: relative;
}
.cal-activity.pending::after,
.month-cal-activity.pending::after{
    content:'';
    width:2px;
    left:0;
    top:0;
    bottom:0;
    position:absolute;
    background:#075DA8;
}
.cal-activity.pending .activity-overflow, .month-cal-activity.pending .activity-overflow{
    background-color:white;
    min-height:100%;
    color:#2B77AE;
    padding-left:6px;
    border:1px solid #2B77AE !important;
    border-radius: 2px;
}
.cal-activity .activity-overflow, .month-cal-activity .activity-overflow{
    line-height:1.3;
}

@keyframes blinkingMeetingBackground {
    0% {
        background-color: #d2effd;
        color: #2b77ad;
    }

    50% {
        background: red;
        color: #fff;
    }

    100% {
        background-color: #d2effd;
        color: #2b77ad;
    }
}

@keyframes blinkingCallBackground {
    0% {
        background-color: #d2effd;
        color: #2b77ad;
    }

    50% {
        background: red;
        color: #fff;
    }

    100% {
        background-color: #d2effd;
        color: #2b77ad;
    }
}

.all-day-activity {
    background-color: #dcf2d4;
    padding: 0 0.25rem;
    color: #168d25;
    font-weight: 600;
    border-left: solid 2px #168d25;
    cursor: pointer;
    position: relative;
    border-bottom: 1px solid #fff;
}

span.activity-overflow {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
}

.first-split-activity::after,
.middle-split-activity::after {
    left: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(136, 183, 213, 0);
    border-left-color: #dcf2d4;
    border-width: 10px;
    margin-top: -10px;
}

.first-split-activity.other-user::after,
.middle-split-activity.other-user::after {
    border-left-color: #d0d7fd !important;
}

.middle-split-activity::before,
.last-split-activity::before {
    right: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(136, 183, 213, 0);
    border-right-color: #dcf2d4;
    border-width: 10px;
    margin-top: -10px;
}

.middle-split-activity.other-user::before,
.last-split-activity.other-user::before {
    border-right-color: #d0d7fd !important;
}

.middle-split-activity,
.last-split-activity,
.middle-split-activity.other-user,
.last-split-activity.other-user {
    border-left: transparent !important;
}

.msp-date-picker {
    width: 180px;
}

.right-clicked-activity {
    background: rgba(0, 126, 227, 1) !important;
    transition: all 0.2s;
    color: #fff !important;
}

.other-user-all-day-activity {
    background-color: #5291c5;
    padding: 0px;
    padding-left: 10px;
    color: white;
    border: solid 1px #eee;
}

.clicked-activity {
    background-color: #0069bd;
    border: solid 1px #eee;
    color: #fff;
    z-index: 100 !important;
}

.view-calendar .view-scroll-all-day {
    overflow-y: scroll;
    overflow-x: hidden;
}

.activity-type-list-css,
[data-uid="Calendar"] .dropdown-item {
    padding: 0.25rem 1rem !important;
}

.wrap {
    text-overflow: ellipsis;
    white-space: nowrap !important;
    overflow: hidden;
}

.mode-edit .wrap {
    overflow: visible;
}

th.wrap {
    overflow: visible;
}

.other-user-month-cal-activity {
    color: white;
    background-color: #6995b9;
    border: solid 1px #fff;
}

.clicked-month-activity {
    background-color: #004177;
    box-shadow: 1px 1px 5px #666;
    width: 100% !important;
}

.activity-date-container {
    background-color: #e3e6f0;
}

.activity-container-row {
    min-height: 80px;
}

.activity-container-row .col {
    width: 14.28% !important;
}

.view-calendar .row {
    margin: 0 !important;
}

#cal-tab .nav-link,
.report-tab .nav-link,
.grid-editor-tab .nav-link,
.page-editor-tab .nav-link {
    padding: 0.25rem 0.5rem;
    font-size: 0.7109375rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}

.page-editor-tab .nav-item,
.grid-editor-tab .nav-item {
    margin-top: 2px;
    margin-right: 2px;
}

.page-editor-tab .nav-item:hover,
.grid-editor-tab .nav-item:hover {
    background-color: #eee;
    border-radius: 0.2rem;
}

.other-user {
    background-color: #d0d7fd !important;
    padding: 0px 6px;
    /* padding-left: 10px; */
    color: #3f51b5 !important;
    border-left: solid 2px #3f51b5 !important;
}

.other-user[data-lockactivity="true"] {
    border-left: solid 2px red !important;
    cursor: no-drop;
    color: red !important;
}

.lookup-item[data-status="rejected"],
.lookup-item[data-status="deleted"] {
    background-color: #ff6758;
    color: #fff;
}

.lookup-item[data-status="accepted"] {
    background-color: #075DA8;
    color: #fff;
}

.lookup-item[data-status="pending"] {
    background-color: #9b9da9;
    color: #fff;
}

.lookup-display-record[data-status="rejected"],
.lookup-display-record[data-status="deleted"] {
    color: #ff1313;
}

.lookup-display-record[data-status="accepted"] {
    color: #075DA8;
}

.lookup-display-record[data-status="pending"] {
    color: #9b9da9;
}

/*Update By Nidhi Sharma, Desc: To add class of blocked-user*/
.deleted-record,
.msp-data-grid .blocked-user,
.object-detail .blocked-user .replaced-user {
    text-decoration: line-through !important;
}

.attendee-type[data-status="rejected"],
.attendee-type[data-status="deleted"] {
    color: #ff1313;
}

.attendee-type[data-status="accepted"] {
    color: #075DA8;
}

.attendee-type[data-status="pending"] {
    color: #9b9da9;
}

.msp-current-time {
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    z-index: 500;
    background-color: #e74a3b;
}

.msp-current-time::before {
    position: absolute;
    width: 8px;
    height: 8px;
    background-color: #e74a3b;
    content: "";
    display: block;
    border-radius: 50%;
    left: -6px;
    top: -3px;
}

#diary-view-section {
    .msp-current-time{
        position: absolute;
        left: 0;
        width: 2px;
        height: calc(100% - 4px);
        z-index: 500;
        top: 3px;
        background-color: #e74a3b;
    &::before{
        left: -3px;
    }
}
.slot-body-row-wrapper{
    &:hover{
        background-color: #075DA8;
        .d-table.border-bottom{
            background-color: #075DA8 !important;
        }
    }
}
}

.item-exists {
    position: relative;
}

.item-exists::after {
    position: absolute;
    content: "";
    width: 3px;
    height: 3px;
    background: #075DA8;
    border-radius: 50%;
    bottom: 3px;
    left: 13px;
}

.item-selected.item-exists::after,
.current-day.item-exists::after {
    background: #fff;
}


/* Marketing Section Start*/

.marketing {
    display: inline;
}

.marketing-template-editor .block-item.heading-txt {
    background-image: url(../images/marketing-template/heading-txt.jpg);
}

.marketing-template-editor .block-item.p-txt {
    background-image: url(../images/marketing-template/p-txt.jpg);
}

.marketing-template-editor .block-item.lower-heading-p-txt {
    background-image: url(../images/marketing-template/lower-heading-p-txt.jpg);
}

.marketing-template-editor .block-item.small-heading-p-txt {
    background-image: url(../images/marketing-template/small-heading-p-txt.jpg);
}

.marketing-template-editor .block-item.heading-p-txt {
    background-image: url(../images/marketing-template/heading-p-txt.jpg);
}

.marketing-template-editor .block-item.p-heading-txt {
    background-image: url(../images/marketing-template/p-heading-txt.jpg);
}

.marketing-template-editor .block-item.full-txt {
    background-image: url(../images/marketing-template/full-txt.jpg);
}

.marketing-template-editor .block-item.two-txt {
    background-image: url(../images/marketing-template/two-txt.jpg);
}

.marketing-template-editor .block-item.single-quot-text {
    background-image: url(../images/marketing-template/single-quot-text.png);
}

.marketing-template-editor .block-item.two-text-colons {
    background-image: url(../images/marketing-template/two-text-colons.jpg);
}

.marketing-template-editor .block-item.three-text-check {
    background-image: url(../images/marketing-template/three-text-check.png);
}

.marketing-template-editor .block-item.two-text-check {
    background-image: url(../images/marketing-template/two-text-check.png);
}

.marketing-template-editor .block-item.text-three {
    background-image: url(../images/marketing-template/text-three.jpg);
}

.marketing-template-editor .block-item.two-unorderedlist-img {
    background-image: url(../images/marketing-template/two-unorderedlist-img.png);
}

.marketing-template-editor .block-item.two-orderedlist-img {
    background-image: url(../images/marketing-template/two-orderedlist-img.png);
}

.marketing-template-editor .block-item.txt-left {
    background-image: url(../images/marketing-template/txt-left.jpg);
}

.marketing-template-editor .block-item.txt-right {
    background-image: url(../images/marketing-template/txt-right.jpg);
}

.marketing-template-editor .block-item.two-img {
    background-image: url(../images/marketing-template/two-img.jpg);
}

.marketing-template-editor .block-item.two-img-txt {
    background-image: url(../images/marketing-template/two-img-txt.jpg);
}

.marketing-template-editor .block-item.full-img {
    background-image: url(../images/marketing-template/full-img.jpg);
}

.marketing-template-editor .block-item.text-three-colons-img {
    background-image: url(../images/marketing-template/text-three-colons-img.jpg);
}

.marketing-template-editor .block-item.three-colons-img {
    background-image: url(../images/marketing-template/three-colons-img.jpg);
}

.marketing-template-editor .block-item.box-text-rgt-img {
    background-image: url(../images/marketing-template/box-text-rgt-img.jpg);
}

.marketing-template-editor .block-item.box-text-lft-img {
    background-image: url(../images/marketing-template/box-text-lft-img.jpg);
}

.marketing-template-editor .block-item.text-list-img {
    background-image: url(../images/marketing-template/text-list-img.jpg);
}

.marketing-template-editor .block-item.h-p-three-img {
    background-image: url(../images/marketing-template/h-p-three-img.jpg);
}

.marketing-template-editor .block-item.img-p-img-p-left {
    background-image: url(../images/marketing-template/img-p-img-p-left.jpg);
}

.marketing-template-editor .block-item.img-four {
    background-image: url(../images/marketing-template/img-four.jpg);
}

.marketing-template-editor .block-item.logo-top {
    background-image: url(../images/marketing-template/logo-top.png);
}

.marketing-template-editor .block-item.unsubscribe-template {
    background-image: url(../images/marketing-template/unsubscribe-template.jpg);
}

.marketing-template-editor .block-item.footer-plain {
    background-image: url(../images/marketing-template/footer-plain.jpg);
}

.marketing-template-editor .block-item.footer-two-col {
    background-image: url(../images/marketing-template/footer-two-col.jpg);
}

.marketing-template-editor .block-item.footer-two-col-ext {
    background-image: url(../images/marketing-template/footer-two-col-ext.jpg);
}

.marketing-template-editor .block-item.box-img-with-buttons-sec1 {
    background-image: url(../images/marketing-template/box-img-with-buttons-sec1.jpg);
}

.marketing-template-editor .block-item.box-img-with-buttons-sec2 {
    background-image: url(../images/marketing-template/box-img-with-buttons-sec2.jpg);
}

.marketing-template-editor .block-item.box-img-with-buttons-sec3 {
    background-image: url(../images/marketing-template/box-img-with-buttons-sec3.jpg);
}

.marketing-template-editor .block-item.box-img-with-buttons-sec5 {
    background-image: url(../images/marketing-template/box-img-with-buttons-sec5.jpg);
}

.marketing-template-editor .block-item.template-buttons {
    background-image: url(../images/marketing-template/template-buttons.jpg);
}

.marketing-template-editor .block-item.template-buttons-2 {
    background-image: url(../images/marketing-template/template-buttons-2.jpg);
}

.marketing-template-editor .block-item.two-column-buttons {
    background-image: url(../images/marketing-template/two-column-buttons.jpg);
}

.marketing-template-editor .block-item.three-column-buttons {
    background-image: url(../images/marketing-template/three-column-buttons.jpg);
}

.marketing-template-editor .block-item.lft-txt-4btn {
    background-image: url(../images/marketing-template/lft-txt-4btn.jpg);
}

.marketing-template-editor .block-item.social-icons {
    background-image: url(../images/marketing-template/social-icons.jpg);
}

.marketing-template-editor .block-item.youtube-full {
    background-image: url(../images/marketing-template/youtube-full.png);
}

.marketing-template-editor .block-item.map-small {
    background-image: url(../images/marketing-template/map-small.jpg);
}

.marketing-template-editor .block-item.map-full {
    background-image: url(../images/marketing-template/map-full.jpg);
}

.block-item.gray-border-4 {
    background-image: url(../images/marketing-template/gray-border-4.jpg);
}

.block-item.gray-border-5 {
    background-image: url(../images/marketing-template/gray-border-5.jpg);
}

.block-item.gray-border-6 {
    background-image: url(../images/marketing-template/gray-border-6.jpg);
}

.block-item.gray-border-8 {
    background-image: url(../images/marketing-template/gray-border-8.jpg);
}

.block-item.gray-border-10 {
    background-image: url(../images/marketing-template/gray-border-10.jpg);
}

.block-item.gray-border-12 {
    background-image: url(../images/marketing-template/gray-border-12.jpg);
}

.marketing-template-editor .block-item .block-item-edt {
    display: none;
}

.marketing-editing-block .block-item {
    min-height: auto !important;
}

.marketing-editing-block .block-item .block-item-edt {
    display: block;
}

.marketing-editing-block {
    fill: unset;
    display: block;
    width: 600px;
    min-height: 400px;
    margin: 0 auto;
    margin-bottom: 100px;
    color: #000;
}

.marketing-full-edit {
    overflow: initial;
    margin-bottom: 200px;
    margin-top: 62px;
    position: relative;
}

.marketing-template-editor {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    height: 100%;
    width: 100%;
}


/* 
.marketing-editing-block-container {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden
} */

.tbl-icons .fa-grip-horizontal {
    cursor: grab;
}

.marketing-editing-block-container {
    background-color: rgb(238, 238, 238);
}

.template-block-droppable {
    height: 5px;
    background-color: #f2f2f2;
    color: #075DA8 !important;
    font-size: 14px !important;
    font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
}

.template-block-droppable.ui-state-hover {
    background-color: #075DA8 !important;
    color: white !important;
}

.template-block-droppable.ui-state-active {
    height: 28px;
    line-height: 28px;
}

.marketing-template-editor .block-item {
    background-repeat: no-repeat;
    background-position: center center;
    min-height: 100px;
    padding: 0px;
    border-bottom: solid 2px #dddfeb;
    position: relative;
}

.block-item.active {
    border: dashed 1px black;
}

.drive-object-detail-view {
    width: 300px;
    min-width: 300px;
    position: relative;
    margin-right: 0px;
    transition: all .3s;
}

.drive-object-detail-view.hide-file-details {
    margin-right: -300px;
}

.drive-object-detail-view .close-icon {
    font-size: 1.3em;
    font-weight: bold;
    border: 0px;
    background: transparent;
    color: #075DA8;
}

.custom-bxs {
    padding-left: 70px;
    padding-right: 70px;
}

.custom-message .quote-lft {
    position: absolute;
    top: -24px;
    left: -40px;
}

.custom-message .quote-rgt {
    position: absolute;
    bottom: -24px;
    right: 40px;
}


/* Marketing Section  End*/

.template-block-from label {
    font-size: 13px;
    line-height: 24px;
    float: left;
    padding-right: 5px;
}

.template-block-from span {
    margin-right: 8px;
}

.marketing-editor {
    max-width: 260px;
    width: 260px;
    padding-left: 45px;
    position: relative;
    border-right: 2px solid #dddfed;
    overflow: auto;
}

.fixedbody {
    position: fixed;
}

.drag-helper {
    padding: 10px;
    font-weight: bold;
    color: #222;
    border: dashed 1px #777;
    width: 210px;
    z-index: 99999;
    display: none;
    /*margin-top: 60px;*/
}

.colorpicker_blank {
    position: absolute;
    width: 20px;
    height: 21px;
    left: 296px;
    top: 142px;
    overflow: hidden;
    border: solid 0.5px #333;
    padding: 1px 3px;
    color: red;
    background-color: #222;
}

.marketing-editor .nav-tabs {
    background: #075DA8;
    width: 45px;
    display: inline-block;
    height: 100vh;
    position: absolute;
    left: 0;
    top: 0;
    padding: 0;
}

.marketing-editor .nav-tabs .nav-link {
    text-align: center;
    padding-left: 0;
    padding-right: 0;
}

.marketing-editor .nav-tabs .nav-link {
    color: #fff;
    transition: all 0.25s;
    border-radius: 0 !important;
    border: 0;
}

.marketing-editor .nav-tabs .nav-item {
    margin: 0;
}

.marketing-editor .nav-tabs .nav-link.active {
    color: #075DA8;
}

[name="TextBlock"],
[name="ImageBlock"],
[name="HeaderBlock"],
[name="ButtonBlock"],
[name="SocialBlock"],
[name="DividerBlock"],
[name="DynamicFieldsBlock"] {
    overflow: auto !important;
    height: calc(100vh - 70px);
}


/* [name="StyleBlock"][data-block="PageBody"] .ddlFont, [name="StyleBlock"][data-block="PageBody"] .txtSize, [name="StyleBlock"][data-block="PageBody"] .fa-bold.editortool, [name="StyleBlock"][data-block="PageBody"] .fa-italic.editortool, [name="StyleBlock"][data-block="PageBody"] .fa-underline.editortool, [name="StyleBlock"][data-block="PageBody"] .fa-subscript.editortool, [name="StyleBlock"][data-block="PageBody"] .fa-superscript.editortool, 
[name="StyleBlock"][data-block="PageBody"] .fa-redo.editortool, [name="StyleBlock"][data-block="PageBody"] .fa-undo.editortool, [name="StyleBlock"][data-block="PageBody"] .fa-align-center.editortool, [name="StyleBlock"][data-block="PageBody"] .fa-align-justify.editortool, [name="StyleBlock"][data-block="PageBody"] .fa-align-left.editortool, [name="StyleBlock"][data-block="PageBody"] .fa-align-right.editortool,  [name="StyleBlock"][data-block="PageBody"] .fa-list-ul.editortool,
[name="StyleBlock"][data-block="PageBody"] .fa-list-ol.editortool, [name="StyleBlock"][data-block="PageBody"] .fa-outdent.editortool, [name="StyleBlock"][data-block="PageBody"] .fa-link, [name="StyleBlock"][data-block="PageBody"] .fa-unlink.editortool
{
  display: none;
} */


/* 
.marketing-editing-block-container {
    width: calc(100% - 350px);
} */

.txtSize {
    min-width: 85px;
}

.templateList.marketing-list {
    padding: 0;
}

.templateList.marketing-list li {
    list-style: none;
    position: relative;
    transition: all 0.2s;
}

.templateList.marketing-list li a {
    display: block;
    padding-bottom: 100%;
    background-size: 100% auto !important;
    transition: all 0.2s;
}

.TemplateSavedBasic .templateList.marketing-list li a {
    background-size: auto 100% !important;
    background-position: 50% !important;
    background-color: #eee !important;
    background-repeat: no-repeat !important;
}

.templateList.marketing-list li .marketing-preview,
.templateList.marketing-list li .template-clone,
.templateList.marketing-list li .template-delete {
    display: none;
    transition: all 0.4s;
    margin-bottom: 4px;
}

.templateList.marketing-list li:hover .marketing-preview,
.templateList.marketing-list li:hover .template-clone,
.templateList.marketing-list li:hover .template-delete {
    display: block;
}

.templateList.marketing-list li:hover a {
    box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15) !important;
    border-color: #075DA8;
}

.templateList.marketing-list li .title {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 1.5;
}

.templateList.marketing-list li:hover .title {
    color: #075DA8;
}

.templateList.marketing-list li .sub-title {
    transition: all 0.2s;
}

.templateList.marketing-list li:hover .sub-title {
    color: #000 !important;
}

.marketing-editing-block .active {
    position: relative;
    z-index: 2;
    outline: black;
    outline-style: dashed;
    outline-width: 2px;
}

#dynamictable .status-dropdown {
    padding: 0.25rem 0.25rem;
}

/*
.marketing-editing-block .active::after {
  z-index: 1;
  border: dashed 2px #000;
  position: absolute;
  left: -2px;
  top: -2px;
  width: calc(100% + 0px);
  height: calc(100% + 0px) !important;
  content: ' ';
  pointer-events: none;
}
.marketing-editing-block  .template-button.active{
    outline: black;
    outline-style: dashed;
    outline-width: 2px;
}
*/

.marketing-editing-block div[contenteditable] {
    outline: 0;
}

#iframeEdit {
    height: calc(100vh - 65px) !important;
    border: 0 !important;
}


/* [name="StyleBlock"] {
    position: fixed;
    top: 0;
    right: 0;
    width: calc(100% - 340px);
    background: #fff;
    z-index: 100;
    color: #fff;
} */

[name="StyleBlock"] .col-auto {
    border: 1px solid #eee;
    border-radius: 0;
    color: #000;
    min-width: 24px;
    padding: 4px 10px;
}

.marketing-css-block {
    max-width: 280px;
    overflow-x: hidden;
    overflow-y: auto;
    height: calc(100vh - 65px);
    box-sizing: border-box;
}

[name="StyleBlock"] .padding-area .col[type="number"] {
    max-width: 60px;
}

[name="StyleBlock"] .padding-area .col.lft-box {
    border-left: #000 2px solid;
}

[name="StyleBlock"] .padding-area .col.rgt-box {
    border-right: #000 2px solid;
}

[name="StyleBlock"] .padding-area .col.bot-box {
    border-bottom: #000 2px solid;
}

[name="StyleBlock"] .padding-area .col.top-box {
    border-top: #000 2px solid;
}

.block-row-template-edit {
    width: 38px;
    position: absolute;
    z-index: 99;
    top: 0;
    left: -38px;
    display: none;
}

.block-row-template-edit .fa.btn {
    max-width: 26px;
    width: 100%;
    height: 26px;
    padding-left: 0;
    padding-right: 0;
}

#img-template-edit {
    width: 30px;
    position: absolute;
    z-index: 3;
    color: #fff;
    font-size: 18px;
    text-align: center;
    padding: 4px;
}

#img-template-resolution {
    position: absolute;
    z-index: 100;
    color: #fff;
    font-size: 12px;
    text-align: center;
    padding: 4px;
}

a,
.lookup-display-record,

/* ,.email-section .email  */

.email-section .email,
.check-details {
    color: #075DA8;
    cursor: pointer;
}

.lookup-display-disabled-record {
    color: #3a3b45;
    cursor: auto;
}

a:hover,
.lookup-display-record:hover
/* ,.email-section .email:hover  */

    {
    text-decoration: underline;
}

[data-ui="multilookup"] .lookup-display-record:hover {
    color: #fff !important;
}

[data-ui="multilookup"] .btn-outline-secondary:hover .lookup-display-record {
    color: #fff !important;
}

.bounced-emails-table .data-row[visible="false"],
.recipients-table .data-row[visible="false"],
.no-result {
    display: none;
}

.bounced-emails-table .data-row[visible="true"],
.recipients-table .data-row[visible="true"] {
    display: table-row;
}

.event-filter-menu .lblChk,
.user-filter-menu .lblChk,
.query-list .lblChk,
.merge-account-fields .lblChk {
    line-height: 1.25;
    display: block;
    margin: 10px 0;
    padding: 0px 25px 1px;
    position: relative;
}

.query-list {
    margin-left: 9px;
    margin-top: 15px;
}


/*-------------Control panel css-------------*/

.control-panel .txtSearch {
    max-width: 600px;
}

.control-panel .box,
.integration-plugins .box {
    color: #075DA8;
    cursor: pointer;
    min-width: 210px;
    max-width: 340px;
    cursor: pointer;
}

.control-panel .box .card,
.integration-plugins .box .card {
    transition: all 0.3s;
}

.control-panel .box:hover .card,
.integration-plugins .box:hover .card {
    background: #075DA8;
    color: #fff;
    border-color: #075DA8;
}

.operaIcon {
    line-height: 52px;
}

.operaIcon::before {
    content: url(../images/opera.png);
}

.link-page:hover .operaIcon::before {
    content: url(../images/opera-w.png);
}

.control-panel .box .card h4,
.integration-plugins .box .card h4 {
    color: #858796;
}

.control-panel .box:hover .card h4,
.integration-plugins .box:hover .card h4 {
    color: #fff;
}

.email-client {
    clear: both;
    margin-top: 20px;
}

.client-name,
.method-name {
    width: 150px;
    height: 150px;
    text-align: center;
    border: 2px solid #e3e6f0;
    border-radius: 0.35rem;
    margin-right: 15px;
    margin-bottom: 15px;
    float: left;
    background: #fff;
    transition: all 0.2s;
    cursor: pointer;
    position: relative;
}

.client-name:hover,
.client-name.active,
.method-name:hover,
.method-name.active {
    border-color: #075DA8;
    color: #075DA8;
    box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15) !important;
}

.client-name.active::after,
.method-name.active::after {
    content: "\f00c";
    width: 30px;
    height: 30px;
    background: #075DA8;
    color: #fff;
    position: absolute;
    right: 0;
    bottom: 0;
    border-radius: 0.3rem 0 0.2rem 0;
    font-family: "Font Awesome 5 Free";
    font-weight: bold;
    line-height: 30px;
}

.client-name img,
.method-name img {
    height: 75px;
    margin-top: 20px;
}

.client-name.zoho img,
.method-name.zoho img {
    max-width: 120px;
    height: auto;
    margin-bottom: 34px;
}

.client-name.custom img,
.method-name.custom img {
    fill: #075DA8;
}

.client-name.zoho span,
.method-name.zoho span {
    line-height: 75px;
    display: block;
    margin-top: 20px;
}

.control-panel .box,
.integration-plugins .box {
    color: #075DA8;
    cursor: pointer;
    min-width: 210px;
    max-width: 340px;
    cursor: pointer;
}

.control-panel .box .card,
.integration-plugins .box .card {
    transition: all 0.3s;
}

.control-panel .box:hover .card,
.integration-plugins .box:hover .card {
    background: #075DA8;
    color: #fff;
    border-color: #075DA8;
}

.operaIcon {
    line-height: 52px;
}

.operaIcon::before {
    content: url(../images/opera.png);
}

.link-page:hover .operaIcon::before {
    content: url(../images/opera-w.png);
}

.control-panel .box .card h4,
.integration-plugins .box .card h4 {
    color: #858796;
}

.control-panel .box:hover .card h4,
.integration-plugins .box:hover .card h4 {
    color: #fff;
}

.email-client {
    clear: both;
    margin-top: 20px;
}

.client-name,
.method-name {
    width: 150px;
    height: 150px;
    text-align: center;
    border: 2px solid #e3e6f0;
    border-radius: 0.35rem;
    margin-right: 15px;
    margin-bottom: 15px;
    float: left;
    background: #fff;
    transition: all .2s;
    cursor: pointer;
    position: relative;
}

.client-name:hover,
.client-name.active,
.method-name:hover,
.method-name.active {
    border-color: #075DA8;
    color: #075DA8;
    box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15) !important;
}

.client-name.active::after,
.method-name.active::after {
    content: "\f00c";
    width: 30px;
    height: 30px;
    background: #075DA8;
    color: #fff;
    position: absolute;
    right: 0;
    bottom: 0;
    border-radius: .3rem 0 .2rem 0;
    font-family: "Font Awesome 5 Free";
    font-weight: bold;
    line-height: 30px;
}

.client-name img,
.method-name img {
    height: 75px;
    margin-top: 20px;
}

.client-name.zoho img,
.method-name.zoho img {
    max-width: 120px;
    height: auto;
    margin-bottom: 34px;
}

.client-name.custom img,
.method-name.custom img {
    fill: #075DA8;
}

.client-name.zoho span,
.method-name.zoho span {
    line-height: 75px;
    display: block;
    margin-top: 20px;
}

.control-panel .box,
.integration-plugins .box {
    color: #075DA8;
    cursor: pointer;
    min-width: 210px;
    max-width: 340px;
    cursor: pointer;
}

.control-panel .box .card,
.integration-plugins .box .card {
    transition: all .3s;
}

.control-panel .box:hover .card,
.integration-plugins .box:hover .card {
    background: #075DA8;
    color: #fff;
    border-color: #075DA8;
}

.operaIcon {
    line-height: 52px;
}

.operaIcon::before {
    content: url(../images/opera.png);
}

.link-page:hover .operaIcon::before {
    content: url(../images/opera-w.png);
}

.control-panel .box .card h4,
.integration-plugins .box .card h4 {
    color: #858796;
}

.control-panel .box:hover .card h4,
.integration-plugins .box:hover .card h4 {
    color: #fff;
}

.email-client {
    clear: both;
    margin-top: 20px;
}

.client-name {
    width: 150px;
    height: 150px;
    text-align: center;
    border: 2px solid #e3e6f0;
    border-radius: 0.35rem;
    margin-right: 15px;
    margin-bottom: 15px;
    float: left;
    background: #fff;
    transition: all 0.2s;
    cursor: pointer;
    position: relative;
}

.client-name:hover,
.client-name.active {
    border-color: #075DA8;
    color: #075DA8;
    box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15) !important;
}

.client-name.active::after {
    content: "\f00c";
    width: 30px;
    height: 30px;
    background: #075DA8;
    color: #fff;
    position: absolute;
    right: 0;
    bottom: 0;
    border-radius: 0.3rem 0 0.2rem 0;
    font-family: "Font Awesome 5 Free";
    font-weight: bold;
    line-height: 30px;
}

.client-name img {
    height: 75px;
    margin-top: 20px;
}

.client-name.zoho img {
    max-width: 120px;
    height: auto;
    margin-bottom: 34px;
}

.client-name.custom img {
    fill: #075DA8;
}

.client-name.zoho span {
    line-height: 75px;
    display: block;
    margin-top: 20px;
}


/*---------- License Progress Bar -------------*/

.license-sec {
    top: -5px;
    width: auto;
    display: flex;
    z-index: 6;
}

.license-sec .progress {
    width: 40px;
    height: 40px;
    line-height: 150px;
    background: none;
    margin: 0 auto;
    box-shadow: none;
    position: relative;
}

.license-sec .progress:after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 0 solid #fff;
    position: absolute;
    top: 0;
    left: 0;
}

.license-sec .progress>span {
    width: 50%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    z-index: 1;
}

.license-sec .progress .progress-left {
    left: 0;
}

.license-sec .progress .progress-bar {
    width: 100%;
    height: 100%;
    background: none;
    border-width: 3px;
    border-style: solid;
    position: absolute;
    top: 0;
}

.license-sec .progress .progress-left .progress-bar {
    left: 100%;
    border-top-right-radius: 80px;
    border-bottom-right-radius: 80px;
    border-left: 0;
    -webkit-transform-origin: center left;
    transform-origin: center left;
}

.license-sec .progress .progress-right {
    right: 0;
}

.license-sec .progress .progress-right .progress-bar {
    left: -100%;
    border-top-left-radius: 80px;
    border-bottom-left-radius: 80px;
    border-right: 0;
    -webkit-transform-origin: center right;
    transform-origin: center right;
    animation: loading-1 1.8s linear forwards;
}

.license-sec .progress .progress-value {
    width: 90%;
    height: 90%;
    border-radius: 50%;
    font-size: 16px;
    color: #334554;
    line-height: 40px;
    text-align: center;
    position: absolute;
    top: 0;
    left: 5%;
    text-align: center;
}

.license-sec .progress.blue .progress-bar {
    border-color: #049dff;
}

.license-sec .progress.blue .progress-left .progress-bar {
    animation: loading-1 1.5s linear forwards 1.8s;
}

.license-sec .progress.yellow .progress-bar {
    border-color: #fdba04;
}

.license-sec .progress.yellow .progress-left .progress-bar {
    animation: loading-2 1s linear forwards 1.8s;
}

.license-sec .progress.pink .progress-bar {
    border-color: #ed687c;
}

.license-sec .progress.pink .progress-left .progress-bar {
    animation: loading-4 0.4s linear forwards 1.8s;
}

.license-sec .progress.green .progress-bar {
    border-color: #1abc9c;
}

.license-sec .progress.green .progress-left .progress-bar {
    animation: loading-5 1.2s linear forwards 1.8s;
}

.license-sec .progress-bar-per {
    text-align: center;
    width: 100%;
    height: 100%;
    display: flex;
    background: rgba(255, 255, 255, 0.95);
}

.license-sec .progress-bar-per svg {
    width: 48px;
    position: relative;
    margin: auto;
}

.license-sec .progress-bar-per svg path {
    stroke: #075DA8;
    stroke-width: 8;
}

.license-sec .progress-bar-per #svgLicenseIssued path {
    stroke: #075DA8;
}

.license-sec .progress-bar-per::after {
    width: 36px;
    height: 36px;
    content: "";
    background: #fff;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    position: absolute;
    border-radius: 50%;
}

.license-sec .progress-bar-per .count {
    position: absolute;
    color: #075DA8;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 100;
    display: block;
}

@keyframes loading-1 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }
}

@keyframes loading-2 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(144deg);
        transform: rotate(144deg);
    }
}

@keyframes loading-3 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
    }
}

@keyframes loading-4 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(36deg);
        transform: rotate(36deg);
    }
}

@keyframes loading-5 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(126deg);
        transform: rotate(126deg);
    }
}

@media only screen and (max-width: 990px) {
    .progress {
        margin-bottom: 20px;
    }
}

.userObjectDetails {
    padding: 3px;
    margin: 5px;
}

.removeUserInvite {
    color: red;
}

.pendingUser {
    border: 2px solid gray;
}

.rejectedUser {
    border: 2px solid red;
}

.acceptedUser {
    border: 2px solid green;
}

.custom-user-invites .btn-outline-secondary {
    padding-right: 0;
    position: relative;
    margin: 0;
    width: calc(100% - 0.65rem);
    text-align: left;
    padding: 0;
    border: 0 !important;
}

.filter-row .custom-user-invites .btn-outline-secondary {
    padding: 0;
    border: 0 !important;
    text-align: left;
    margin: 0;
}

.filter-row .custom-user-invites .btn-outline-secondary:hover {
    background: #fff;
    color: #858796;
}

.filter-row .custom-user-invites .btn-outline-secondary .fa-times {
    top: 2px;
    right: 0;
}

.mode-edit .custom-user-invites .btn-outline-secondary {
    border: 0 !important;
    padding: 0 !important;
    text-align: left;
    margin: 0;
}

.mode-edit .custom-user-invites .btn-outline-secondary:hover {
    background: #fff;
    color: #858796;
}

.mode-edit .custom-user-invites .btn-outline-secondary .fa-times {
    right: 0;
    top: 2px;
}

.custom-user-invites .fa-times {
    position: absolute;
    right: 0rem;
    top: 2px;
}


/*Media Library CSS Start*/

.right-panel-image {
    width: calc(100% - 250px);
    transition: all 0.2s;
    background: #eeeeee;
}

.img-lst-hdr {
    display: inline-block;
    font-weight: normal;
    margin-bottom: 0;
    color: #777;
    border: 0;
    padding: 8px;
}

.thumb_img {
    padding-bottom: calc(100% - 52px);
    text-align: center;
    vertical-align: middle;
    background-color: #fafafa !important;
    background-size: 100% auto !important;
    background-position: center center !important;
    margin-bottom: 52px;
}

.img_name {
    width: 100%;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}

.div-img-list {
    float: left;
    cursor: pointer;
    position: relative;
    transition: all 0.2s;
}

.img-tool {
    display: none;
    transition: all 0.2s;
}

.toolDiv {
    position: absolute;
    right: 5px;
    top: 5px;
    width: 30px;
    transition: all 0.2s;
}

.div-img-list:hover {
    border-color: #075DA8;
    background: #fff;
    box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15) !important;
}

.div-img-list:hover .img-tool {
    display: block;
}

.m_lib {
    position: fixed;
    z-index: 1030;
    left: 0;
    right: 0;
    top: 0;
    transition: all;
    background: rgba(255, 255, 255, 1);
    animation: media 0.55s;
    height: 100%;
}

.m_lib_cnt {
    width: 100%;
    float: left;
}

.left-panel-tree {
    overflow-y: auto;
    box-sizing: border-box;
    height: calc(100vh - 65px);
    max-width: 250px;
}

.left-panel-tree .dir {
    transition: all 0.2s;
    display: flex;
}

.left-panel-tree .dir .fa.fa-folder {
    width: 16px;
    padding-top: 3px;
}

.left-panel-tree .dir .folder-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.left-panel-tree .dir.ui-active {
    background: #075DA8 !important;
    color: #ffffff !important;
    border-radius: 0;
}

.left-panel-tree .dir i {
    margin-right: 3px;
}

.left-panel-tree .dir.ui-active i {
    color: #ffffff;
}

.div-img-list .btn.btn-sm {
    min-width: 30px;
}

.tdPrimaryKey {
    background: red;
    color: #fff;
    width: 120px;
}

.thPrimaryKey {
    background: #075DA8;
    color: #fff;
    width: 120px;
}


/*Media Library CSS END*/

.multiselect-container.dropdown-menu {
    max-height: 300px;
    overflow: auto;
    /* Width added for production issue - 4425 */
    width: max-content;
}

.tbl-module-list .multiselect-container.dropdown-menu {
    height: auto !important;
}

/* Resolving production issue - 4425 Start */
.multiselect-container.dropdown-menu li {
    padding: 0 7px 0 0;
}

/* End */


/* Resolving production issue - 4425 Start */
.multiselect-container.dropdown-menu li {
    padding: 0 7px 0 0;
}

/* End */
.multiselect-container.search-multiselect {
    min-height: 300px;
    width: 100%;
    max-width: 100%;
}

/*#region Suraj*/

.img-editor {
    background-color: #fff;
    z-index: 9999;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.img-editor .header {
    background-color: #fff;
    text-align: right;
    padding: 8px 12px;
}

.img-editor .header .settings {
    text-align: left;
    float: left;
}

.img-editor .body {
    background-color: rgba(0, 0, 0, 0.9);
    border: 0;
    height: calc(100% - 110px);
    width: 100%;
    position: relative;
    overflow: hidden;
}

.img-editor #img-drag-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* text-align:center;  */
}

.img-editor .body .image-selector {
    position: absolute;
    border: 2px solid rgba(255, 255, 255, 0.75);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
}

.img-editor .body .image-selector .img {
    opacity: 1;
    position: absolute;
}

.img-editor .body .uploaded-image {
    display: inline-block;
    opacity: 0.4;
    margin: auto;
    text-align: center;
}

.img-editor .body .uploaded-image img {
    display: block;
}

.img-editor .footer {
    background-color: #fff;
    padding: 8px 12px;
}

.img-editor .slidecontainer {
    width: 100%;
}

.img-editor .slider {
    -webkit-appearance: none;
    z-index: 2;
    position: relative;
    appearance: none;
    width: 100%;
    height: 6px;
    background: transparent;
    outline: none;
    opacity: 1;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
}

.img-editor .range-cover {
    position: relative;
    width: 40%;
    display: inline-block;
}

.img-editor .range-filler {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 9px;
    height: 6px;
    background-color: #075DA8;
}

.img-editor .slider-range-filler {
    position: absolute;
    width: 100%;
    z-index: 0;
    left: 0;
    top: 9px;
    height: 6px;
    background-color: #ddd;
}


/* Mouse-over effects */

.img-editor .slider:hover {
    opacity: 1;
}

.img-editor .slider::-webkit-slider-thumb:hover {
    background: #000;
}


/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */

.img-editor .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    background: #075DA8;
    cursor: pointer;
    border-radius: 50%;
    z-index: 10;
    position: relative;
    transition: all 0.3s;
}

.img-editor .slider::-moz-range-thumb {
    -moz-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    background: #075DA8;
    cursor: pointer;
    border-radius: 50%;
    z-index: 10;
    position: relative;
    transition: all 0.3s;
}

.profile-pic {
    position: relative;
}

.profile-pic:hover .profile-img-edit {
    opacity: 1;
}

.profile-img-edit {
    padding: 4px;
    position: absolute;
    right: 0;
    bottom: 0;
    display: block;
    color: #fff;
    transition: all 0.2s;
    opacity: 0;
}

.pac-container {
    z-index: 10001;
}

.search-controls {
    text-align: center;
    padding: 16px;
}

.search-controls .txtSearch::placeholder {
    color: #fff;
}

.admin-controls-list .control-item,
.integration-plugins .control-item {
    cursor: pointer;
}

.msp-popup .item.selected {
    background: #075DA8;
    color: #fff;
}

.msp-ctrl-texteditor.hotkey-enabled .popup-container div.item {
    min-width: 100px;
    border-bottom: dotted 1px black;
}

.user-replace-settings .moudules-list .modules {
    padding: 0;
    margin: 0;
    list-style: none;
    max-height: 250px;
    overflow: auto;
}

.modal-replace-user .modal-sm {
    min-width: 500px !important;
}

.table-responsive.grid-table-container.tblUsers::before,
.table-responsive.grid-table-container.tblUsers::after {
    display: none;
}

.operavipupcomingstay {
    cursor: pointer;
}

/*#endregion Suraj*/

.pleaseWait {
    display: none;
}

.recipients-table.table {
    width: auto;
}

.recipients-table.table th,
.recipients-table.table td {
    white-space: pre !important;
}

.recipients-table.table td {
    max-width: fit-content;
}


/* Advance Filter Start */

.advc-filter-sec {
    position: fixed;
    width: 500px;
    top: 0;
    right: 0;
    background: #fff;
    z-index: 1040;
    height: 100%;
    margin-right: -500px;
    transition: all 0.2s ease-in;
}

.advc-filter-sec.report-edit-page {
    z-index: 5;
    /* this z-index is gone down to avoid overlap of manage menu and report page filter Reports-> Single Report -> Edit Report -> Filter Report*/
}

.advc-filter-sec.active {
    margin-right: 0px !important;
}

.advc-filter-sec .list-filter-edit {
    height: 0px;
    overflow: hidden;
    transition: all 0.3s;
}

.advc-filter-sec.edit-mode .list-filter-edit {
    height: auto !important;
}

.advc-filter-sec.other-user-filter .btn-add-edit-filter[action-type="edit"],
.advc-filter-sec.system-define-filter .btn-add-edit-filter[action-type="edit"],
.advc-filter-sec.system-define-filter .btn-delete-filter,
.advc-filter-sec .filter-footer,
.advc-filter-sec .btn-save-filter,
.advc-filter-sec .btn-cancel-filter,
.advc-filter-sec.edit-mode .btn-add-edit-filter,
.advc-filter-sec.edit-mode .selected-filter-detail {
    display: none;
}

.advc-filter-sec.edit-mode .filter-footer,
.advc-filter-sec.edit-mode .btn-save-filter,
.advc-filter-sec.edit-mode .btn-cancel-filter {
    display: inline-block;
}

.advc-filter-sec .treeview-conditions .filter-ul {
    border-left: dashed 1px;
    position: relative;
    padding: 20px 0 20px 20px;
    margin: 20px 0 10px 0;
}

.advc-filter-sec .treeview-conditions .filter-ul::before {
    position: absolute;
    top: calc(50% - 10px);
    left: -10px;
    border: dashed 1px;
    padding: 1px 2px;
    font-size: 0.7em;
    font-weight: bold;
    background: #fff;
    z-index: 2;
}

.advc-filter-sec .treeview-conditions .filter-ul .filter-li:first-child {
    position: absolute;
    top: -18px;
}

.advc-filter-sec .treeview-conditions .filter-ul .filter-li:last-child {
    position: absolute;
    bottom: -10px;
}

.advc-filter-sec .treeview-conditions ul.condition-AND::before {
    border-color: #075DA8;
    color: #075DA8;
    content: "AND";
}

.advc-filter-sec .treeview-conditions ul.condition-OR::before {
    border-color: #e74a3b;
    color: #e74a3b;
    content: "OR";
}

.advc-filter-sec .treeview-conditions>.filter-ul>.filter-li:last-child .btnDeleteCondition {
    display: none;
}

.advc-filter-sec .treeview-conditions .filter-ul .filter-li .filter-ul {
    margin-top: 30px;
    margin-bottom: 30px;
}

.advc-filter-sec .treeview-conditions .filter-ul .filter-li {
    list-style: none;
    position: relative;
}

.advc-filter-sec .treeview-conditions .filter-ul .filter-li::before {
    position: absolute;
    top: calc(50% + 2px);
    left: -20px;
    border: dashed 0.6px;
    width: 20px;
    content: " ";
}

.advc-filter-sec .treeview-conditions ul.condition-AND .filter-li::before,
.treeview-conditions ul.condition-AND {
    border-color: #075DA8;
}

.advc-filter-sec .treeview-conditions ul.condition-OR .filter-li::before,
.treeview-conditions ul.condition-OR {
    border-color: #e74a3b;
}

.advc-filter-sec .treeview-conditions .close {
    position: absolute;
    top: 5px;
    right: 8px;
    font-size: 12px;
}


/*Advance Filter End*/

.users-calendar-sharing .user-list-main li.active {
    background-color: #1abc9c;
}

.users-calendar-sharing .user-list-main ul,
.users-calendar-sharing .user-list-secondary ul {
    list-style-type: none;
    margin: 0px;
    padding: 3px;
}

.activity-rightclick-menu .dropdown-item.active,
.activity-rightclick-menu .dropdown-item:active,
.table-filter .dropdown-item.active {
    background: none !important;
    color: #075DA8 !important;
    position: relative;
}

.activity-rightclick-menu .dropdown-item.active[data-key="busy"]::after,
.activity-rightclick-menu .dropdown-item.active[data-key="free"]::after,
.activity-rightclick-menu .dropdown-item.active[data-key="lock"]::after,
.activity-rightclick-menu .dropdown-item.active[data-key="unlock"]::after,
.table-filter .dropdown-item.active::after {
    content: "\f00c";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    position: absolute;
    right: 10px;
}

.userTimingTable .userDetail {
    font-style: bold;
}

.table-filter .dropdown-item.active::after {
    left: 5px;
}

.table-filter .dropdown-item.default-item {
    position: relative;
}

.table-filter .dropdown-item.default-item::before {
    content: "\f08d";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    position: absolute;
    right: 10px;
    color: #3a3b45;
    font-size: 0.7rem;
    top: 10px;
}

.fas,
.fa,
.far {
    font-weight: 800 !important;
}

.close {
    color: #075DA8;
}

.text-black {
    color: #000 !important;
}

.comp-signature .tool {
    position: absolute;
    right: 0;
    bottom: 0;
}

.dropdown-nav.cal-month-menu,
.dropdown-nav.cal-year-menu {
    -webkit-appearance: none;
    -moz-appearance: none;
    padding: 0;
    height: auto;
    font-size: 16px;
    color: #000;
    height: 22px;
    transition: all 0.2s;
    margin-top: -2px;
}

.monthYear {
    height: 22px;
}

.dropdown-nav.cal-month-menu {
    width: 50px;
    margin-left: 8px;
}

.dropdown-nav.cal-year-menu {
    width: 70px;
}

.reset-userFilter,
.reset-allEventFilters {
    color: #075DA8;
    float: right;
    cursor: pointer;
}


/*======================OnBoard Process Page============================*/

#onboard .carousel-indicators {
    position: relative;
}

#onboard .carousel-caption {
    position: relative;
    right: unset;
    bottom: unset;
    left: unset;
    z-index: 10;
    padding-top: 0;
    padding-bottom: 0;
    color: #fff;
    text-align: center;
}

#onboard .carousel-indicators li {
    background: #075DA8;
    width: 10px;
    height: 10px;
    border: 0;
    border-radius: 50%;
}

#onboard .carousel-caption .icon {
    width: 80px;
    height: 80px;
    line-height: 74px;
    display: inline-block;
    font-size: 2rem;
}

#onboard .btn-primary {
    min-width: 200px;
}

#onboard .select-img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1rem;
}

#onboard .update-img {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    text-align: center;
    padding: 6px;
    background: rgba(0, 0, 0, 0.45);
    color: #fff;
}

#side-nav {
    width: 100%;
    max-width: 300px;
}

#side-nav nav {
    max-width: 280px;
    top: 0;
    width: 100%;
}


/*===============================My Drive==================================*/

.main-drive .folder-box {
    width: 100%;
    margin-bottom: 0.9rem;
    cursor: pointer;
}

.main-drive .folder-box,
.main-drive .file-box {
    padding-left: 1rem;
    padding-right: 1rem;
}

.folder-box.favourite .card-title {
    padding-right: 16px;
    position: relative;
}

.folder-box.favourite .card-title .fa-heart {
    position: absolute;
    right: 0;
    top: 4px;
}

.file-box.favourite .file-bg {
    position: relative;
}

.file-box.favourite .file-bg .fa-heart {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 17px;
    height: 17px;
    background-color: white;
    border-radius: 50%;
    padding: 3px 2px 2px 2px;
}

.main-drive .folder-box .card,
.main-drive .file-box .card,
.folder-box .card i {
    transition: all 0.17s;
}

.download-disabled {
    opacity: 0.5;
}

.gm-style .gm-svpc {
    display: none !important;
}

.main-drive .folder-box:hover .card,
.main-drive .file-box:hover .card {
    box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15) !important;
    color: #075DA8;
    border-color: #075DA8;
}

.main-drive .folder-box:hover .card i {
    color: #075DA8;
}

.main-drive .file-box {
    width: 100%;
    margin-bottom: 0.9rem;
    cursor: pointer;
}

.main-drive .file-loader {
    padding-left: 1rem;
    padding-right: 1rem;
    width: 100%;
    margin-bottom: 0.9rem;
}

.main-drive .file-bg {
    height: 100%;
    padding-bottom: calc(100% - 48px);
    background-size: cover !important;
    background-color: #f4f4f4 !important;
    position: relative;
    width: 100%;
    background-position: top center !important;
}

.main-drive .file-bg.default-icon {
    background-size: unset !important;
    background-position: center center !important;
}

.main-drive .folder-box .card-title,
.catname {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.main-drive .file-box .card-title {
    height: 48px;
    box-sizing: border-box;
    padding: 1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.main-drive .file-box .card-title span {
    flex-grow: 1;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.main-drive .file-box .card-title a {
    opacity: 0;
    transition: all 0.3s;
}

.main-drive .file-box:hover .card-title a {
    opacity: 1;
}

.main-drive-grid,
.main-drive-list {
    height: calc(100vh - 190px);
    overflow: auto;
    overflow-x: hidden;
}

//added by ankush: for outline on selected file when clicked from email
.shared-active {
    //changed color of selected file: QPC-7125
    outline: 3px solid #FF0000;
}

.drive-selected {
    box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15) !important;
    color: #075DA8 !important;
    border-color: #075DA8 !important;
}

.drive-selected.card i.fa-folder,
.drive-selected i.fa-folder.text-secondary {
    color: #075DA8 !important;
}

.drive-rightclick-menu {
    padding: 0 !important;
}

.drive-rightclick-menu .dropdown-item {
    color: #3a3b45 !important;
}

.drive-rightclick-menu .dropdown-item:active {
    color: #fff !important;
}

.main-drive-grid .breadcrumb {
    background: none;
    border-radius: 0;
    font-size: 1rem;
    color: #075DA8;
}

.breadcrumb li {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100px;
}

.main-drive-grid .breadcrumb a {
    color: inherit;
    cursor: pointer;
}

.file-bg.fa::before {
    color: #858796;
    position: absolute;
    left: 50%;
    top: 50%;
    font-size: xxx-large;
    right: 0;
    margin-top: -24px;
    margin-left: -16px;
}

.file-box:hover .file-bg.fa::before {
    color: #075DA8;
}

.main-drive-grid .breadcrumb li:last-child a {
    color: #858796;
    cursor: none;
}

.gridfile-box i {
    font-size: x-large;
    color: #858796;
}

.gridfile-box.drive-selected i {
    color: #075DA8 !important;
}

.gridfile-box td>div,
.gridfolder-box td>div {
    padding-top: 0.5rem !important;
}

.main-drive-spacebar>p {
    margin-bottom: 4px;
    margin-top: 8px;
    font-size: 12px;
    color: #075DA8;
}

.main-drive-spacebar .progress {
    width: 250px;
}

.upload-button {
    overflow: hidden;
    position: relative;
}

.upload-button form {
    visibility: hidden;
    position: absolute;
}

.upload-section {
    position: fixed;
    left: calc(100% - 420px);
    bottom: 10px;
    width: 400px;
    z-index: 1011;
}

.upload-section ul {
    list-style: none;
    padding: 0;
    margin: 0;
    max-height: 200px !important;
    overflow: auto !important;
}

.upload-section ul li {
    padding: 0.5rem 1rem;
    border-bottom: 1px solid #eee;
}

.upload-section .col.filename {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.upload-section #progress-bar-per {
    float: right;
    position: relative;
}

.upload-section #progress-bar-per::after {
    content: "";
    position: absolute;
    left: 10%;
    top: 10%;
    background: #fff;
    width: 80%;
    height: 80%;
    border-radius: 50%;
}

.upload-section #progress-bar-per svg {
    width: 40px;
}

.upload-section #progress-bar-per.inactive {
    animation: spinUploader 2s linear infinite;
}

.upload-section #progress-bar-per.inactive svg text {
    font-size: 0;
}

.upload-section #progress-bar-per.inactive svg path {
    stroke: white;
}

.upload-section #progress-bar-per svg path {
    stroke: rgb(19, 126, 227);
}

.upload-section #count {
    position: absolute;
    color: #075DA8;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 100;
    display: block;
}

.upload-section #progress-bar-per.inactive #count,
.upload-section #progress-bar-per.file-uploaded #count {
    display: none;
}

.upload-section #progress-bar-per.file-uploaded svg path {
    stroke: #168d25;
}

.upload-section #progress-bar-per.file-uploaded::before {
    content: "\f00c";
    position: absolute;
    left: 50%;
    top: 50%;
    color: #168d25;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    z-index: 101;
    transform: translate(-50%, -50%);
}

@keyframes spinUploader {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.welcome-drive {
    border: dashed 1px #ddd;
    text-align: center;
    color: #858796;
}

.welcome-drive .icon {
    font-size: 56px;
}

.welcome-drive .text {
    font-size: 20px;
}

.action-prompt {
    bottom: 20px;
    color: #fff;
    left: 20px;
    z-index: 99;
    display: flex;
}

.action-prompt .text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 250px;
    width: 100%;
}

.move-folder-list .list-group-item:first-child {
    border-radius: 0;
    border-top: 0;
}

.copyto-folder-list .list-group-item:first-child {
    border-radius: 0;
    border-top: 0;
}

.move-folder-list .list-group-item:last-child {
    border-radius: 0;
}

.copyto-folder-list .list-group-item:last-child {
    border-radius: 0;
}

.drive-padding {
    padding-bottom: 0 !important;
}

.drive-padding .mb-5.table-sec {
    margin-bottom: 0;
}

.move-file-container {
    position: relative;
    padding-top: 35px !important;
}

.copyto-file-container {
    position: relative;
    padding-top: 35px !important;
}

.move-breadcrumb {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: -35px;
    z-index: 2;
}

.copyto-breadcrumb {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: -35px;
    z-index: 2;
}

.folder-item-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.minimise-progressbar,
.maximise-progressbar,
.close-progressbar {
    opacity: .5;
}

.text-ellipse {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.main-drive-list.grid-table-container::after {
    border-bottom: 0 !important;
}

.folder-item-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.minimise-progressbar,
.maximise-progressbar,
.close-progressbar {
    opacity: 0.5;
}

.text-ellipse {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.main-drive-list.grid-table-container::after {
    border-bottom: 0 !important;
}

.grid-download .fa-download:hover {
    color: #075DA8 !important;
}

.upload-section #progress-bar-per.upload-error #count {
    display: none;
}

.upload-section #progress-bar-per.upload-error::before {
    content: "\f01e";
    position: absolute;
    font-family: "Font Awesome 5 Free";
    font-weight: bold;
    left: 10%;
    top: 10%;
    background: #fff;
    width: 80%;
    height: 80%;
    border-radius: 50%;
    z-index: 100;
    line-height: 33px;
    color: red;
    opacity: 1;
}


/*=====================Create Campaign===========================*/

.campaign-container {
    width: 100%;
}

.campaign-container .campaign .nav-item {
    flex: auto;
    position: relative;
    font-size: 1.2rem;
}

.campaign-container .campaign .nav-item:hover .nav-link,
.campaign-container .campaign .nav-item .nav-link:hover,
.campaign-container .campaign .nav-item .nav-link {
    border: 0;
}

.campaign-container .campaign .nav-item .circle {
    width: 30px;
    height: 30px;
    display: inline-block;
    border-radius: 50%;
    background: #adb5bd;
    color: #fff;
    text-align: center;
}

.campaign-container .campaign .nav-item .nav-link {
    color: #adb5bd;
}

.campaign-container .campaign .nav-item.selected .nav-link {
    color: #000;
}

.campaign-container .campaign .nav-item.selected .circle {
    background: #000;
}

.campaign-container .campaign .nav-item.completed .nav-link {
    color: #075DA8;
}

.campaign-container .campaign .nav-item.completed .circle {
    background: #075DA8;
}

.campaign-container .campaign .nav-item:first-child::before,
.campaign-container .campaign .nav-item:last-child::after {
    display: none;
}

.campaign-container .campaign .nav-item::before,
.campaign-container .campaign .nav-item::after {
    content: "";
    position: relative;
    flex: 1;
    margin: 0;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.1);
    top: 50%;
}

.campaign-container .campaign .nav-item.selected::before,
.campaign-container .campaign .nav-item.selected::after {
    background-color: #000;
}

.campaign-container .campaign .nav-item.completed::before,
.campaign-container .campaign .nav-item.completed::after {
    background-color: #075DA8;
}

.campaign-type label {
    padding: 2rem;
    width: 100%;
    display: block;
    border: 1px solid #075DA8;
    color: #075DA8;
    font-size: 1.4rem;
    position: relative;
}

.campaign-type label.rounded.active::before {
    content: "\f00c";
    position: absolute;
    font-size: 1.6rem;
    color: #fff;
    left: 14px;
    top: 30px;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
}

.campaign-type label input {
    visibility: hidden;
}

.campaign-type label.rounded.active {
    border: 1px solid #075DA8;
    color: #fff;
    background: #075DA8;
}

.close-editor {
    position: fixed;
    right: 8px;
    top: 2px;
}

.profile-pic {
    position: relative;
}

.profile-pic:hover .edits {
    display: block;
}

.edits {
    padding: 4px;
    position: absolute;
    right: 0;
    top: 0;
    display: none;
    background: #075DA8;
    color: #fff;
}

#progress-bar-per {
    text-align: center;
    width: 100%;
    height: 100%;
    display: flex;
    background: rgba(255, 255, 255, 0.95);
}

#progress-bar-per svg {
    width: 200px;
    position: relative;
    margin: auto;
}

.progress-bar-sec .modal-dialog {
    top: 50%;
    transform: translate(0, -50%) !important;
    margin-top: 0;
    margin-bottom: 0;
}

.user-working-days .starttime,
.user-working-days .endtime {
    max-width: 140px;
}

.user-working-days .endtime {
    margin-left: 0.5rem;
    margin-bottom: 0.5rem;
}

.permit-users-list li {
    display: inline-block;
    padding-right: 6px;
    position: relative;
}

.permit-users-list li::after {
    position: relative;
    content: ",";
}

.permit-users-list li:last-child::after {
    display: none;
}

.form-control[data-field="MSP_lookup"],
.form-control[data-ui="lookup"] {
    line-height: 1 !important;
}

.minimize-panel {
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
    width: 100%;
}

.modal-min-snippit {
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    text-align: left;
    width: auto;
    display: flex;
}

.modal-min-snippit span {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    width: auto;
    padding: 0.4rem;
}

.participant-action .dropdown-toggle::after {
    display: none;
}

.participant-list {
    max-height: 180px;
    overflow-y: auto;
}

.participant-list li {
    margin-bottom: 2px;
}

.participant-action {
    opacity: 0;
    transition: all 0.3s;
}

.participant-list li:hover .participant-action {
    opacity: 1;
}

.activity-chart .nav-item {
    color: #333;
    border: 0;
    border-bottom: 2px solid transparent;
    font-weight: normal;
    padding: 0.2rem 0.35rem;
    transition: all 0.2s;
    cursor: pointer;
}

.activity-chart .nav-item.active {
    color: #075DA8;
    border: 0;
    border-bottom: 2px solid #075DA8;
    cursor: pointer;
}

.activity-chart .collapse-head.collapsed .nav-item {
    display: none;
}


/* .byLocation-table th,
.byLocation-table td {
    padding: .5rem;
} */


/*========================Loader Css==============================*/

#main-loading {
    position: fixed;
    background: rgba(255, 255, 255, 0.8);
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 1100;
    display: none;
}

#main-loading.active,
.loading.active {
    display: block;
}

#main-loading img,
.loading img {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.loading {
    position: absolute;
    background: rgba(255, 255, 255, 0.8);
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 1010;
}

.loading-container {
    position: relative;
}


/*===================Code mirror style=============================*/

.full-screen {
    position: fixed;
    z-index: 9999;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    font-size: 1.5em;
    max-height: 100%;
}


/*===================Responsive breakpoints=======================*/

@media (min-width: 320px) {

    .main-drive .folder-box,
    .main-drive .file-box,
    ul.templateList.marketing-list li,
    .div-img-list {
        max-width: 50%;
        min-width: 50%;
    }
}

@media (min-width: 576px) {

    .main-drive .folder-box,
    .main-drive .file-box,
    ul.templateList.marketing-list li,
    .div-img-list {
        max-width: 50%;
        min-width: 50%;
    }

    .main-drive .file-box {
        height: 50%;
    }

    .modal-sm {
        min-width: 500px !important;
    }
}

@media (min-width: 768px) {

    .main-drive .folder-box,
    .main-drive .file-box,
    ul.templateList.marketing-list li,
    .div-img-list {
        max-width: 25%;
        min-width: 25%;
    }

    .modal-dialog {
        min-width: 715px !important;
    }

    .employee-status-modal.modal-lg {
        min-width: 500px !important;
        max-width: 500px;
    }

    .main-drive .file-box {
        height: 25%;
    }


}

@media (min-width: 992px) {

    .main-drive .folder-box,
    .main-drive .file-box,
    ul.templateList.marketing-list li,
    .div-img-list {
        max-width: 20%;
        min-width: 20%;
    }

    .main-drive .file-box {
        height: 20%;
    }
}

@media (min-width: 1200px) {

    .main-drive .folder-box,
    .main-drive .file-box,
    ul.templateList.marketing-list li,
    .div-img-list {
        max-width: 16.6%;
        min-width: 16.6%;
    }

    .main-drive .file-box {
        height: 16.6%;
    }
}

.disabled-lookup,
.multi-attachment .disabled-attachment li,
.msp-ctrl-texteditor-disabled .msp-ctrl-texteditor {
    background-color: #f8f9fc;
}

.form-control:disabled {
    background-color: #f8f9fc;
    opacity: 1;
    -webkit-text-fill-color: #858796;
}

.modal-header .close {
    outline: 0;
}

.modal-header h5,
.page-title-info h5,
.object-name {
    word-break: break-all;
}

.navbar-search input:focus,
.navbar-search button:focus {
    box-shadow: none;
}

.focus-shadow.active {
    box-shadow: 0 0 0 0.2rem rgba(0, 126, 227, 0.25);
    border-radius: 0.35rem;
    transition: all 0.2s;
}

.social-icons a,
.template-button a,
.td-col a {
    position: relative;
    display: inline-block;
}

.clicksMap .template-mail-body a {
    position: relative;
    overflow: visible !important;
}

.link-count-style {
    padding-top: 3px;
    font-size: 10px;
    background: #bb5206;
    border-radius: 50%;
    color: #fff;
    width: 18px;
    height: 18px;
    display: inline-block;
    text-align: center;
    position: absolute;
    top: -7px;
    right: -8px;
    vertical-align: sub;
    position: absolute;
}


/* .template-button .link-count-style {
  top: -16px;
  right: -28px;
} */

.table.table-bordered.recipients-table .data-row.selected {
    background: rgba(0, 126, 227, 0.07);
}

.notification-div {
    max-height: 400px;
    overflow: auto !important;
    padding-top: 0 !important;
}

.marketing-editor .block.active {
    display: block;
}

.marketing-editor .block {
    display: none;
}

.mail-box {
    background-color: #f8f9fc;
    border: 1px solid #eee;
    border-top: 0;
    padding: 0;
    margin-bottom: 20px;
}

.mail-box-header {
    background-color: #ffffff;
    border: 1px solid #eee;
    border-bottom: 0;
    padding: 20px;
}

.mail-box-header h2 {
    margin-top: 0;
    width: calc(100% - 134px);
    /* overflow: hidden; */
    /* height: 32px; */
    text-overflow: ellipsis;
}

.mailbox-content .tag-list li a {
    background: #ffffff;
}

.mail-body {
    border-top: 1px solid #e7eaec;
    padding: 20px;
    word-break: break-all;
}

.mail-text {
    border-top: 1px solid #e7eaec;
}

.mail-text .note-toolbar {
    padding: 10px 15px;
}

.mail-body .form-group {
    margin-bottom: 5px;
}

.mail-text .note-editor .note-toolbar {
    background-color: #f9f8f8;
}

.mail-attachment {
    border-top: 1px solid #e7eaec;
    padding: 20px;
    font-size: 12px;
}

.mail-attachment ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.mailbox-content {
    background: none;
    border: none;
    padding: 10px;
}

.fileName li {
    display: inline-block;
    margin-right: 6px;
    color: #075DA8;
    border: 1px solid #075DA8;
    border-radius: 0.35rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.fileName li a {
    padding: 0.375rem 0.75rem;
    display: block;
}

.fileName li:hover {
    background: #075DA8;
    color: #fff;
}

.fileName li:hover a {
    color: #fff;
    text-decoration: none;
}

.fileName li a i {
    margin-right: 4px;
}

.current-month-day {
    background-color: #075DA8;
    color: white;
}

.context-menu {
    z-index: 900;
}

@media print {
    .mail-content {
        max-width: 100% !important;
        width: 100%;
    }

    .printemail,
    .mail-print-btn,
    #page-nav {
        display: none !important;
    }
}

.msp-time-picker .item {
    color: #000 !important;
}

.msp-time-picker .item.item-selected,
.msp-time-picker .item:hover {
    color: #fff !important;
}

.lblFileUpload {
    width: calc(100% - 50px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0 !important;
    line-height: 1.4;
}

.notification-item {
    word-break: break-word;
}

input.txtSearch[placeholder="Search Box"] {
    display: none;
}

.map input.txtSearch[placeholder="Search Box"] {
    width: 270px;
    top: 10px !important;
    display: block;
}

.msp-view-collection {
    overflow-y: auto;
}

.bell-size {
    font-size: 1.2rem;
}

.msp-coll-lbl {
    position: relative;
}

.msp-coll-lbl::after {
    position: absolute;
    content: "\f107";
    right: 8px;
    top: 4px;
    font-weight: bold;
    font-family: "Font Awesome 5 Free";
}

.campaign-report-content svg>g>g:last-child {
    pointer-events: none;
}

.img-profile-glow {
    box-shadow: 0 0 0 0.2rem rgba(0, 126, 227, 0.25);
}

.img-profile-container:hover .img-profile .img-profile-task {
    box-shadow: 0 0 0 0.2rem rgba(0, 126, 227, 0.25);
}

.img-profile-container .user-name {
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    top: 6px;
}

.object-picture:hover {
    box-shadow: 0 0 0 0.2rem rgba(0, 126, 227, 0.25);
    cursor: pointer;
}

.licence-hover {
    padding-bottom: 4px;
    transition: all .3s;
    border-radius: 5px;
}

.licence-hover:hover {
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.licence-hover:hover .filename {
    color: #075DA8 !important;
}

/* master-licence-issued - hover */
.master-license-issued .master-licence-hover-tooltip {
    visibility: hidden;
    width: 50%;
    white-space: nowrap;
    background-color: #555;
    color: #fff;
    text-align: left;
    border-radius: 6px;
    padding: 5px 5px;
    position: absolute;
    z-index: 1;
    top: 110%;
    /* left: 11%; */
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
}

.master-license-issued .master-licence-hover-tooltip::after {
    content: "";
    position: absolute;
    bottom: 98%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

.master-license-issued:hover .master-licence-hover-tooltip {
    visibility: visible;
    opacity: 1;
}

/* .feature-license-issued - hover*/

.feature-license-issued .feature-licence-hover-tooltip {
    visibility: hidden;
    width: 50%;
    white-space: nowrap;
    background-color: #555;
    color: #fff;
    text-align: left;
    border-radius: 6px;
    padding: 5px 5px;
    position: absolute;
    z-index: 1;
    top: 110%;
    /* left: 46%; */
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
}

.feature-license-issued .feature-licence-hover-tooltip::after {
    content: "";
    position: absolute;
    bottom: 98%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

.feature-license-issued:hover .feature-licence-hover-tooltip {
    visibility: visible;
    opacity: 1;
}

/* .app-license-issued - hover*/

.app-license-issued .app-licence-hover-tooltip {
    visibility: hidden;
    width: 50%;
    white-space: nowrap;
    background-color: #555;
    color: #fff;
    text-align: left;
    border-radius: 6px;
    padding: 5px 5px;
    position: absolute;
    z-index: 1;
    top: 110%;
    left: 51.5%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
}

.app-license-issued .app-licence-hover-tooltip::after {
    content: "";
    position: absolute;
    bottom: 98%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

.app-license-issued:hover .app-licence-hover-tooltip {
    visibility: visible;
    opacity: 1;
}

/* .domain-license-issued - hover*/

.domain-license-issued .domain-licence-hover-tooltip {
    visibility: hidden;
    width: 50%;
    white-space: nowrap;
    background-color: #555;
    color: #fff;
    text-align: left;
    border-radius: 6px;
    padding: 5px 5px;
    position: absolute;
    z-index: 1;
    top: 110%;
    /* left: 80%; */
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
}

.domain-license-issued .domain-licence-hover-tooltip::after {
    content: "";
    position: absolute;
    bottom: 98%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

.domain-license-issued:hover .domain-licence-hover-tooltip {
    visibility: visible;
    opacity: 1;
}


.img-profile-calendar {
    height: 50px;
}

.swal-modal {
    width: 500px;
}


/*===================Reports=======================*/
/* Report Editor Padding Issue resolved Start */
.custom-control-label.auto-refresh-button {
    position: relative;
    margin-bottom: 0;
    vertical-align: top;
    padding: 5px 0 0 10px;
}

/* Report Editor Padding Issue resolved End */
.reports .calendar-left-panel {
    width: 315px;
    max-width: 315px;
    min-width: 315px;
}

[data-uid*="ReportEditor"] .reports .calendar-left-panel {
    max-height: calc(100vh - 145px) !important;
}

.report-folder {
    list-style: none;
    padding: 0;
}

.report-folder li:first-child .btn-pin-top {
    display: none;
}

.report-folder li {
    position: relative;
    z-index: inherit;
    margin-bottom: 1px;
}

.report-folder li.selected {
    background-color: #c8c8c9;
}

.report-folder>li a {
    padding: .35rem 1rem .35rem .5rem;
    border-radius: .2rem;
    /* color: #5a5c69 !important; */
    text-decoration: none;
    transition: all .2s;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    display: block;
    padding-right: 30px;
    user-select: none;
}

.report-folder>li.custom>a::before {
    content: "\f4ff";
    position: absolute;
    left: 10px;
    bottom: 10px;
    font-weight: 900;
    font-family: "Font Awesome 5 Free";
    font-size: 5px;
    color: #fff;
}


/* .report-folder li {
  position: relative;
  z-index: inherit;
} */

.report-folder>li a {
    padding: 0.35rem 1rem 0.35rem 0.5rem;
    border-radius: 0.2rem;
    color: #5a5c69;
    text-decoration: none;
    transition: all 0.2s;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    display: block;
}


/* .report-folder>li.custom>a::before {
  content: "\f4ff";
  position: absolute;
  left: 10px;
  bottom: 10px;
  font-weight: 900;
  font-family: "Font Awesome 5 Free";
  font-size: 5px;
  color: #fff;
} */

.report-folder li .fa-thumbtack,
.report-folder li .btn-group {
    position: absolute;
    padding: 5px;
}

.report-folder li .fa-thumbtack,
.report-folder li .btn-group {
    position: absolute;
    padding: 5px;
}

.folder-owner {
    margin-left: 0px;
}

.report-folder li .fa-cog {
    position: relative;
    padding: 0;
    margin-right: 20px;
}

.report-folder {
    list-style: none;
    padding: 0;
}

.report-folder li .fa-thumbtack {
    top: 0;
    right: 15px;
    z-index: auto;
}

.report-folder li .btn-group {
    right: 0;
    top: 0;
    z-index: auto;
    padding: 0;
}

.report-folder li .btn-group .btn {
    padding: 4px;
}

.report-folder li.active .btn {
    color: #fff;
}

.folder-owner {
    margin-left: 0px;
}

.report-folder li .btn {
    visibility: hidden;
}

.report-folder>li.active>a {
    color: #fff !important;
    background: #075DA8;
}

.report-folder>li:hover>a {
    color: #3a3b45 !important;
    background-color: #d4daed;
}

.report-folder>li>ul {
    padding-left: 15px;
    list-style: none;
}

.report-folder li:hover .btn {
    visibility: visible;
    color: #3a3b45;
}

.report-list {
    width: calc(100% - 315px);
}

.report-format {
    width: 140px;
}

.report-show {
    width: 100px;
}

.text-over-ellipse {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.rename-report .btnSave {
    display: flex;
    justify-content: center;
    padding: 6px;
}

.rename-report .btnSave i {
    font-size: 14px;
}

#report-group .btn-light {
    background: #f8f9fc;
    border-color: #e3e6f0;
}

#report-filter.advc-filter-sec {
    margin: 0;
    position: relative;
    height: auto;
    width: 100%;
}

#report-filter.advc-filter-sec .filter-footer {
    display: block;
}

#report-filter .msp-popup {
    margin-left: -29px;
}

.btn-refresh-report,
.text-refresh-report {
    display: none;
}

.report-structure-changed .btn-refresh-report,
.report-structure-changed .text-refresh-report {
    display: inline;
}

.report-structure-changed .report-preview-table {
    background-color: #eeeeee;
}


.static-header {
    position: fixed;
    top: 0px;
    background: #fff;
    z-index: 1;
}

.sticky-header {
    position: sticky;
    top: 0px;
    z-index: 1;
}

thead.sticky-header::before {
    content: "";
    top: 0px;
    position: absolute;
    border-top: 2px solid #dddfeb;
    width: 100%;
}

thead.sticky-header::after {
    content: "";
    bottom: 0px;
    position: absolute;
    border-top: 2px solid #dddfeb;
    width: 100%;
}

.formula-field-list {
    overflow: auto;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 240px;
}

.CodeMirror {
    border: 1px solid silver;
}

.CodeMirror-hints,
.CodeMirror-hint,
.CodeMirror-hint-active {
    z-index: 10000 !important;
}

/*===================Reports END=======================*/

/* Tag Designing**/

.select-all-grid {
    float: left;
    padding: 0.2rem 1.2rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    background-color: #ffffff;
    border-color: rgba(0, 126, 227, 0.07);
    z-index: 101;
    position: relative;
}

.select-all-grid-text:not(.selected) {
    cursor: pointer;
    text-decoration: underline;
    color: #075DA8;
}

.clear-selection-grid {
    cursor: pointer;
    color: red;
    text-decoration: underline;
}

.singleDeleteBtn {
    color: red;
    margin-left: 10px;
}

.custom-tag-control {
    padding-left: 2rem;
    display: block;
}


/* Tag Designing Ends**/

.drive-share .remove-user-share {
    color: #858796;
}

.drive-share .remove-user-share:hover {
    color: #000;
    cursor: pointer;
}

.exp-time-pickup {
    max-width: 250px;
}

.lh-1 {
    line-height: 1;
}

.op-color-picker {
    position: relative;
}

.op-color-picker .fas {
    color: #000;
    padding: 2px;
    opacity: 0.75;
    background: #fff;
}


/*==========Store===========*/

.store-app-search {
    max-width: 700px;
}

.store-app-list .card-deck .card {
    margin-top: 1rem;
    max-width: 320px;
    min-width: 240px;
}

.store-app-list .card-deck .card-img-top {
    text-align: center;
    padding: 2em 0;
}

.store-app-list .card-deck .card-img-top img {
    display: inline-block;
    height: 80px;
}

.store-app-list .card-deck .card .card-title {
    font-weight: 500;
}

.store-app-list .card-deck .card .btn-outline-light {
    color: #3a3b45;
    background-color: #f8f9fc;
    border-color: #f8f9fc;
}

#headingOne h6[aria-expanded="true"],
#headingTwo h6[aria-expanded="true"],
#headingThree h6[aria-expanded="true"] {
    color: #075DA8 !important;
}

/*Added by suraj on 28Feb2022 for Opera Store*/
#headingOne h6[aria-expanded="true"] .down-arrow,
#headingTwo h6[aria-expanded="true"] .down-arrow,
#headingThree h6[aria-expanded="true"] .down-arrow {
    transform: rotate(180deg);
}

#headingOne h6[aria-expanded="false"] .down-arrow,
#headingTwo h6[aria-expanded="false"] .down-arrow,
#headingThree h6[aria-expanded="false"] .down-arrow {
    transform: rotate(0deg);
}

/*changes end*/

#headingOne h6.disabled,
#headingTwo h6.disabled,
#headingThree h6.disabled {
    opacity: 0.4;
}

[data-pagename="AppPrice"] .card-deck .card {
    min-width: 250px;
    max-width: 250px;
    margin-top: 1em;
}

.company-footer {
    color: white;
}

.company-footer a {
    color: white;
}

.datafield-icon {
    padding-left: 20px;
    position: relative;
}

.datafield-icon.autoincrement::before {
    content: "\f3bf" !important;
}

.datafield-icon.time::before {
    content: "\f017" !important;
}

.datafield-icon.date::before {
    content: "\f073" !important;
}

.datafield-icon.database::before {
    content: "\f1c0" !important;
}

.datafield-icon.email::before {
    content: "\f0e0" !important;
}

.datafield-icon.dropdown::before {
    content: "\f150" !important;
}

.datafield-icon.multiselect::before {
    content: "\f0ae" !important;
}

.datafield-icon.url::before {
    content: "\f0ac" !important;
}

.datafield-icon.phone::before {
    content: "\f3cd" !important;
}

.datafield-icon.text::before {
    content: "\f15c" !important;
}

.datafield-icon.number::before {
    content: "\f1ec" !important;
}

.datafield-icon.int::before {
    content: "\f129" !important;
}

.datafield-icon.decimal::before {
    content: "\f525" !important;
}

.datafield-icon.currency::before {
    content: "\f155" !important;
}

.datafield-icon.eformula::before {
    content: "\f698" !important;
}

.datafield-icon.formula::before {
    content: "\f53e" !important;
}

.datafield-icon.checkbox::before {
    content: "\f14a" !important;
}

.datafield-icon.date::before {
    content: "\f784" !important;
}

.datafield-icon.datetime::before {
    content: "\f073" !important;
}

.datafield-icon.textarea::before {
    content: "\f036" !important;
}

.datafield-icon.email::before {
    content: "\f658" !important;
}

.datafield-icon.lookup::before {
    content: "\f002" !important;
}

.datafield-icon.multilookup::before {
    content: "\f00e" !important;
}

.datafield-icon.image::before {
    content: "\f03e" !important;
}

.datafield-icon.file::before {
    content: "\f1c6" !important;
}

.datafield-icon.location::before {
    content: "\f689" !important;
}

.datafield-icon.collection::before {
    content: "\f0ce" !important;
}

.datafield-icon.objectid::before {
    content: "\f246" !important;
}

#wrapper {
    overflow: hidden;
}

.datafield-icon::before {
    position: absolute;
    left: 6px;
    top: 0px;
    content: "\f0c8";
    font-weight: bold;
    font-family: "Font Awesome 5 Free";
    opacity: .6;
}

.task-quick-view {
    width: 300px;
    min-width: 300px;
    transition: all .3s;
    margin-right: -300px;
    height: 100vh;
    overflow: auto;
}

.task-quick-view.quick-show {
    margin-right: 0;
}

.task-quick-view-header {
    display: flex;
    padding: 0.5rem 1rem;
    border-bottom: 1px solid #ddd;
}

.task-icon {
    width: 27px;
    border-radius: 50%;
    text-align: center;
    line-height: 25px;
    cursor: pointer;
}

.tasks-sublist-menu .dropdown-item {
    padding: 0.1rem 1.5rem 0.1rem 2rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.table-filter .tasks-sublist-menu .dropdown-item.active::after {
    left: 15px;
}

.add-quick-task {
    padding: 0.5rem 1rem;
}

.no-quick-task img {
    width: 100%;
    padding: 0.5rem 1rem
}

.expand-addtask {
    position: absolute;
    bottom: 0;
    left: calc(50% - 17px);
}

.task-lists .todo-edit {
    max-width: 45px;
}

.task-lists .todo-edit .task-edit-icon {
    display: none;
}

.task-lists .todo-check {
    max-width: 30px;
    position: relative;
}


/********** Shop Css Begins*****************/

.user-timing-info-row {
    text-align: center;
    cursor: pointer;
}

.user-timings {
    min-width: 100px;
}

.week-timing {
    border: 1px solid #dddfeb;
    border-radius: 5px;
    padding: 6px;
}

.week-startdate {
    font-size: 13px;
}

.week-enddate {
    font-size: 13px;
}

.week-timing-display {
    padding: 0px 15px;
}

.staff-timing-calendar {
    position: absolute;
    z-index: 1060;
    visibility: visible;
    top: 30px !important;
    left: 80px !important;
}

.first-shift-timing {
    background-color: #075DA8;
    border-radius: 5px;
    color: #fff;
    white-space: nowrap;
    padding: 4px;
    font-size: 12px;
    margin-bottom: 2px;
}

.second-shift-timing {
    background-color: #075DA8;
    border-radius: 5px;
    color: #fff;
    white-space: nowrap;
    padding: 4px;
    font-size: 12px;
    margin-bottom: 2px;
}

.user-timing-staffname {
    color: #075DA8;
}

.user-timing-column {
    color: #075DA8;
    min-width: 130px;
}

.extended-bottom-appointment {
    border-bottom: dotted 2px #2b77ad !important;
}

.extended-top-appointment {
    border-top: dotted 2px #2b77ad !important;
}

.del-product-btn {
    color: red !important;
}

/********** Shop Css Ends*****************/


/*=======Help=========*/

.help-search {
    max-width: 650px;
    position: relative;
    margin: 0 auto;
}

.help-search input {
    height: 50px;
    padding-left: 20px;
    padding-right: 40px;
    font-size: 18px;
}

.help-search::after {
    position: absolute;
    content: "\f002";
    font-family: "Font Awesome 5 Free";
    font-weight: bold;
    right: 16px;
    top: 10px;
    color: #858796;
    font-size: 20px;
}

.help-topic-card {
    font-size: 20px;
    transition: all 0.2s;
}

.help-topic-card a {
    text-decoration: none;
    color: #858796;
}

.help-topic-card a i {
    display: inline-block;
    background-color: #858796;
    width: 48px;
    height: 48px;
    line-height: 48px;
    text-align: center;
    border-radius: 4px;
    font-size: 24px;
    color: #fff;
    margin-right: 10px;
    transition: all 0.2s;
}

.topic-text {
    flex: auto;
}

.topic-text .small {
    font-size: 0.875rem;
}

.help-topic-card:hover {
    border-color: #075DA8;
}

.help-topic-card:hover a i {
    background: #075DA8;
}

.help-document-detail .calendar-left-panel {
    max-width: 300px;
}

.help-detail-search {
    position: relative;
}

.help-detail-search::after {
    position: absolute;
    content: "\f002";
    font-family: "Font Awesome 5 Free";
    font-weight: bold;
    right: 10px;
    top: 4px;
    color: #858796;
    font-size: 16px;
}

.help-document-detail .report-folder>li a {
    white-space: normal;
}

.help-detail-search input {
    padding-right: 30px;
}

.help-detail-container {
    border-radius: 6px;
    background-color: #fff;
    border-style: solid;
    border-width: 1px;
    border-color: #e1e5ee;
    padding: 2.5rem;
    height: 100%;
    overflow: auto;
    font-size: 0.875rem;
}

.operavipupcomingstay {
    color: #075DA8
}

.operavipupcomingstay:hover {
    text-decoration: underline;
}

.help-detail-container h1 {
    margin-bottom: 20px;
}

.help-detail-container ul li {
    padding: 0.2rem 0;
}

#help-tree,
#help-tree li {
    list-style-type: none;
}

#help-tree li {
    padding: 0.35rem 0rem;
    line-height: 1;
}

#help-tree li.active {
    color: #fff;
    background: #075DA8;
}

#help-tree {
    margin: 0;
    padding: 0;
    font-size: 0.8rem;
}

#help-tree .caret {
    cursor: pointer;
    user-select: none;
    padding-top: 0.35rem;
    padding-bottom: 0.35rem;
    display: block;
}

#help-tree .caret::before {
    content: "\f054";
    color: #000;
    display: inline-block;
    margin-right: 6px;
    font-family: "Font Awesome 5 Free";
    font-weight: bold;
    position: absolute;
}

#help-tree .caret-down::before {
    -ms-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    color: #075DA8;
}

#help-tree .nested {
    color: #000000;
    padding-left: 16px;
}

#help-tree .nested.active {
    display: block;
}

#help-tree .nested.active li {
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 4px;
}

#help-tree .nested-child {
    padding-left: 25px;
}

.help-left-panel {
    height: 100%;
}

.search-found {
    background-color: #075DA8;
}

.beta-nav-menu {
    background: #fff;
    padding: 0px 3px 1px 3px;
    color: #ffffff;
    border-radius: 2px;
    margin-left: 4px;
    font-size: 13px !important;
    margin-bottom: auto;
}

.beta-nav-menu-compressed {
    position: absolute;
    top: 2px;
    right: 142px;
    font-size: 10px !important;
    background: #fff;
    padding-left: 3px;
    padding-right: 3px;
    color: #ffffff;
    border-radius: 2px;
    margin-left: 4px;
    padding-bottom: 1px;
}

.highlight {
    background-color: #075DA8;
    color: #ffffff;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.7);
    -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.7);
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.7);
}

.highlight {
    padding: 1px 4px;
    margin: 0 -4px;
}

.mainMenuModuleName {
    word-break: break-word;
}


/********** HELP Css Start*****************/

.help-detail-container {
    overflow-y: scroll;
    height: calc(100vh - 20vh);
}

.word-count {
    position: absolute;
    right: 5px;
    top: 5px;
    background: white;
    z-index: 2;
    padding: 1px;
}

.word-count .space {
    padding: 3px;
}

mark,
.mark {
    padding: unset;
}

.help-left-panel {
    max-width: 200px;
}


/********** HELP Css End *****************/

.company-footer a {
    color: white;
    max-width: 200px;
}


/********** HELP End Start*****************/

/* .task-quick-view {
    width: 300px;
    min-width: 300px;
    transition: all .3s;
    margin-right: -300px;
}

.task-quick-view.quick-show {
    margin-right: 0;
}

.task-quick-view-header {
    display: flex;
    padding: 0.5rem 1rem;
    border-bottom: 1px solid #ddd;
}

.task-icon {
    width: 27px;
    border-radius: 50%;
    text-align: center;
    line-height: 25px;
    cursor: pointer;
} */

.tasks-sublist-menu .dropdown-item {
    padding: 0.1rem 1.5rem 0.1rem 2rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.table-filter .tasks-sublist-menu .dropdown-item.active::after {
    left: 15px;
}

.add-quick-task {
    padding: 0.5rem 1rem;
}

.no-quick-task img {
    width: 100%;
    padding: 0.5rem 1rem
}

.expand-addtask {
    position: absolute;
    bottom: 0;
    left: calc(50% - 17px);
}

.task-lists .todo-edit {
    max-width: 45px;
}

.task-lists .todo-edit .task-edit-icon {
    display: none;
}

.task-lists .todo-check {
    max-width: 30px;
    position: relative;
}

.task-lists .todo-check input {
    margin-top: 3px;
}

.task-view:hover .todo-edit .task-edit-icon {
    display: block;
}

.task-view:hover .todo-edit .task-pri {
    display: none;
}

.uploaded-files .multi-action-button {
    max-width: 65px;
    visibility: hidden;
}

.uploaded-files .multi-action-button i {
    line-height: 1;
}

.uploaded-files li:hover .multi-action-button {
    visibility: unset;
}

.uploaded-files .multi-file-name {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: .25em 0;
}

.uploaded-files li.list-group-item {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border: 0;
    border-bottom: 1px solid #eee;
    z-index: 1;
    margin-bottom: 0;
}

.task-list-container .task-action-icon {
    display: none;
}

.task-list-container .task-list-item:hover .task-action-icon {
    z-index: 10;
    display: unset;
}

.content-editable {
    border: 1px solid #d1d3e2;
    border-radius: 4px;
    padding: 4px;
}

.content-editable:focus {
    color: #3a3b45;
    border-radius: 4px;
    background-color: #fff;
    border-color: #64baff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 126, 227, 0.25);
    padding: 4px;
}

.disable-build-ctrl {
    pointer-events: none;
    opacity: 0.8;
}

.task-word-wrap {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-word;
    width: auto;
}

.new-task-round {
    background: #027ee3;
    width: 5px;
    height: 5px;
    border-radius: 100%;
    /* vertical-align: middle; */
    margin: 6px 6px 0 6px;
}

.disable-build-ctrl {
    pointer-events: none;
    opacity: 0.8;
}

.grid-activity-pending {
    color:var(--primary);
}

.help-left-panel .shadow {
    height: calc(100vh - 81px);
    overflow: auto;
}

.help-sub-topic>a {
    display: block;
    padding-left: 14px;
}

.grid-activity-locked {
    cursor: no-drop;
    border-left: solid 2px red !important;
    color: red !important;
}

.beta-nav-menu {
    background: #fff;
    padding-left: 3px;
    padding-right: 3px;
    color: #ffffff;
    border-radius: 2px;
    margin-left: 4px;
    font-size: 11px;
    padding-bottom: 1px;
}

.modal-min-height300 {
    min-height: 300px !important;
}

[data-pagename="OperaIntegrationConfiguration"] .grid-table-container {
    min-height: unset !important;
}

.sub-dropdown {
    position: relative;
}

.sub-dropdown ul {
    position: absolute;
    background: white;
    display: none;
}

.sub-dropdown:hover ul {
    display: block;
    right: calc(-100% + 10px);
    top: 0px;
    margin: 0px;
    padding: 0px;
    overflow: auto;
}

.dropdown-item.sub-dropdown::after {
    content: "\f054";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    position: absolute;
    right: 10px;
}

.categories-activity-drop-item .dropdown-item.active::after,
.showas-activity-drop-item .dropdown-item.active::after {
    content: "\f00c";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    position: absolute;
    right: 10px;
}

.selectcolor label {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 8px;
    margin-bottom: 8px;
    background-color: #075DA8;
}

.selectcolor label.active {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, .25)
}

.selectcolor label input {
    position: absolute;
    left: 0;
    top: 0;
    visibility: hidden;
}

/* For participates status (Busy/Free/Out Of Office) of activity QPC-2453*/
.status-busy {
    background-repeat: no-repeat;
    background-image: url(./../images/busy.jpg) !important;
    background-size: 7px 100%;
}

.status-out-of-office {
    background-repeat: no-repeat;
    background-image: url(./../images/out-office.jpg) !important;
    background-size: 7px 100%;
}

.status-busy-activity {
    background-repeat: no-repeat !important;
    background-image: url(./../images/busy.jpg) !important;
    background-size: 2px 100% !important;
    border-left: none !important;
}

.status-out-of-office-activity {
    background-repeat: repeat-y !important;
    background-image: url(./../images/out-office.jpg) !important;
    background-size: 4px auto !important;
    border-left: none !important;
}

.custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 2.65rem;
    pointer-events: all;
    border-radius: .8rem;
    padding: 12px;
}

.custom-switch .custom-control-label::after {
    top: calc(.25rem + 3px);
    left: calc(-2.25rem + 7px);
    width: calc(1rem - 4px);
    padding: 8px;
}

.custom-control-label {
    position: relative;
    margin-bottom: 0;
    vertical-align: top;
}

.table-scroll-x {
    overflow-x: scroll;
    height: 110px;
}

.newpipelines,
.pipelinesDecision,
.todayActivities,
.todaysAndOverDue {
    max-height: 288px;
    ;
}

.overduecolor {
    background-color: red;
}


/* today view */

.today-view h3 {
    font-size: 1.125rem;
}

.today-view .bg-light {
    background: #F0F2FA;
}

.today-view h4 {
    font-size: 1rem;
}

.today-view .activity-type {
    font-size: 10px;
    margin-left: 10px;
    padding: 1px 8px;
    background: #EDF5FD;
    font-weight: 500;
    border-radius: 20px;
    color: #6AAEED;
    height: auto;
}

.today-view .activity-type.call {
    background: #FDF3EB;
    color: #EDA467;
}

.today-view .activity-type.others {
    background: #EFFDF7;
    color: #68CCA1;
}

.today-view .activity-bg {
    padding: 10px;
}

.today-view .pipeline-bg {
    padding: 10px;
}

.today-view .bind-tasks {
    padding: 10px;
}

.today-view .activity-list {
    margin-bottom: 6px !important;
    padding: 10px;
}

.today-view .decisionpipeline-list {
    margin-bottom: 6px !important;
    padding: 10px;
}

.today-view .pipeline-list {
    margin-bottom: 6px !important;
    padding: 10px;
}

.c-user-list .c-user {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #075DA8;
    display: inline-block;
    margin-right: 4px;
    background-size: 100% !important;
    color: #fff;
    line-height: 28px;
    text-align: center;
}

.c-user-list .c-user::after {
    display: none;
}

.c-user-limit {
    line-height: 30px;
    text-align: center;
    color: #B5B8C4;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.dv-contact {
    min-width: 86px;
}

.dv-account {
    min-width: 130px;
}

.dv-tabs .nav-link {
    border: 0;
    border-bottom: 1px solid #F0F2FA;
    color: #000;
    padding: 0 14px 6px 14px;
}

.dv-tabs.nav-tabs .nav-link.active,
.dv-tabs.nav-tabs .nav-item.show .nav-link {
    background-color: transparent !important;
    color: #075DA8;
    border-color: #075DA8;
}


/* For Activity */

.activity-list.dv-overdue {
    background: #FCF5F5 !important;
}

.activity-list.dv-overdue h6 a {
    color: #D06969 !important;
}

.activity-list.dv-overdue .dates {
    color: #C79B9B !important;
}


/* For pipeline */

.decisionpipeline-listt.dv-overdue {
    background: #FCF5F5 !important;
}

.decisionpipeline-list.dv-overdue h6 a {
    color: #D06969 !important;
}

.decisionpipeline-list.dv-overdue .dates {
    color: #C79B9B !important;
}


/* For decision pipeline */

.decisionpipeline-list.dv-overdue {
    background: #FCF5F5 !important;
}

.decisionpipeline-list.dv-overdue h6 a {
    color: #D06969 !important;
}

.decisionpipeline-list.dv-overdue .dates {
    color: #C79B9B !important;
}

.tasks-list-overdue {
    background-color: #FCF5F5 !important;
}

.today-view .tasks-list {
    margin-bottom: 6px !important;
    padding: 10px;
}

.overdue-dates {
    color: #C79B9B !important;
}

.overdue-priority {
    color: #C79B9B !important;
}

.pipeline-prority {
    color: #a90423 !important;
    background: #efd2de !important;
}

.activity-priority {
    color: #f13b6d !important;
    background: #f3edee !important;
}

.today-view .bg-light {
    max-height: calc(100vh - 200px);
    overflow: auto;
}

.new-todayoverdue-clss {
    margin-top: 7px;
}

.activity-type-other {
    color: #5eea83 !important;
    background: #eaf7ed !important;
}

.moduleSetting {
    white-space: pre-wrap !important;
    word-break: break-all;
}

.delete-drive-version .swal-title {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.page-info .message {
    position: relative;
    background: #EDF5FD;
    padding: 6px 14px 8px 14px;
    border-radius: 4px;
    /* line-height: 1.5; */
    display: inline-block;
}

.disable-past-dates {
    pointer-events: none;
    background: #dcdcdc;
}

.texteditor strong {
    font-weight: bold;
}

.msp-ctrl-lookup {
    overflow: hidden;
}

.app-no-show {
    background-color: #ff5e5e !important;
    color: rgba(255, 255, 255, 0.9);
}

.app-confirmed {
    background-color: #FFFF00 !important;
}

.app-new {
    background-color: #a6e5bd !important;
    color: black;
}

.app-checkout {
    background-color: #c7c7b5 !important;
    color: white;
}

.chart-list {
    overflow: hidden;
    width: 270px;
    position: absolute;
    display: none;
    right: 10px;
    z-index: 1000;
    background-color: rgb(255, 255, 255);
    border: 1px solid #ccc;
    top: 45px;
    padding: 5px;
    text-align: left;
}

ul.chart-sub-lst {
    margin: 0px;
    padding: 0px;
    text-align: left;
}

ul.chart-sub-lst li {
    margin: 0px;
    padding: 0 8px;
    text-align: center;
    display: inline-block
}

ul.chart-sub-lst li a {
    color: #000;
    filter: grayscale(100%);
}

ul.chart-sub-lst li a:hover {
    color: #0c67b0;
    text-decoration: none;
    filter: grayscale(0%);
}

ul.chart-sub-lst li span {
    display: block;
    padding-top: 0px;
    font-size: 12px;
    font-weight: bold;
}

ul.chart-sub-lst li a.active {
    filter: grayscale(0%);
    color: #0c67b0;
    border-bottom: solid 2px #0c67b0;
    display: block;
    padding: 1px;
}

/* ul.chart-sub-lst li a.active {border: 1px solid #ccc;} */

ul.chart-sub-lst li img {
    width: 24px;
}

.coming-soon {
    background-color: red;
    border-radius: 5px;
    text-align: center;
    color: white;
    padding: 2px;
    font-size: 8px;
    margin: 0;
}

.side-tab {
    max-width: 1200px;
    width: calc(100% - 300px);
}

.date-ctrl-report {
    max-width: 100% !important;
}

.date-ctrl-rangefilter {
    max-width: 100% !important;
}

/* dashboard css start */
.grid-stack {
    background: #fff;
}

.grid-stack-item-content {
    background: #fff;
    border: solid 1px #e3e6f0;
}

.grid-stack {
    padding: 0px;
    background-color: #fff;
    background-size: calc(8.3333333333%) calc(8.3333333333%);
    background-image:
        linear-gradient(to right, #d2effd 10px, transparent 1px),
        linear-gradient(to left, #d2effd 10px, transparent 1px),
        linear-gradient(to top, #d2effd 10px, transparent 1px),
        linear-gradient(to bottom, #d2effd 10px, transparent 1px)
}

.dashboard-grid {
    padding: 20px;
    background-color: #d2effd;
    border-radius: 4px;
}

.dash-report-chart {
    width: 100%;
    height: calc(100% - 43px);
    position: absolute;
}

.dash-report-chart1 {
    width: 100%;
    height: calc(50% - 22px);
    position: absolute;
}

.dash-report-chart2 {
    width: 100%;
    top: 50%;
    height: calc(50% - 22px);
    position: absolute;
}

.expand-dash-report-chart1 {
    width: 50%;
    position: absolute;
}

.expand-dash-report-chart2 {
    width: 50%;
    left: 50%;
    position: absolute;
}

/* .GoToReport {
    position: absolute;
    bottom: 0px;
    width: 100%;
    cursor: pointer
} */
.GoToReport:hover {
    text-decoration: underline;
    cursor: pointer;
}

.Compare-First {
    position: absolute;
    z-index: 1;
    padding: 15px;
}

.Compare-Second {
    position: absolute;
    z-index: 1;
    padding: 15px;
    top: 50%;
}

.Expand-Compare-First {
    position: absolute;
    z-index: 1;
    padding: 15px;
}

.Expand-Compare-Second {
    position: absolute;
    z-index: 1;
    padding: 15px;
    left: 50%;
}

/* dashboard css end */
.img-profile-calender-initials {
    border-radius: 50%;
    background: #075DA8;
    height: 50px;
    width: 50px;
    color: #fff;
    display: inline-block;
    font-size: 20px;
    padding-top: 10px;
}

.img-link-block {
    display: inline-flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    border-radius: 50%;
}

.short-name {
    background: #075DA8;
    font-size: 20px;
    color: #fff;
    text-align: center;
    width: 50px;
    height: 50px;
}

.img-profile {
    display: inline-block;
    text-align: center;
    background: #075DA8;
    line-height: 2.4;
    color: white;
}

.img-profile-grid {
    background: #075DA8;
    display: flex;
    color: #fff;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
}

.img-profile-shop {
    display: inline-block;
    text-align: center;
    background: #075DA8;
    color: white;
    height: 50px;
    width: 50px;
    max-width: 50px;
    line-height: 50px;
    font-size: 20px;
}

.swal-text {
    text-align: center !important;
}

/* satyam start */
.acc-merge-selected {
    color: red !important;
    ;
}

/* satyam end  */
.img-view-bg {
    width: 200px;
    height: 250px;
    object-fit: cover;
    cursor: pointer;
}

.grid-cell .img-view-bg {
    width: 50px;
    height: 50px;
    object-fit: cover;
    cursor: pointer;
    border-radius: 50%;
}

.img-profile-task {
    display: inline-block;
    text-align: center;
    background: #075DA8;
    line-height: 2.4;
    color: white;
    height: 32px;
    width: 32px;
}

/* VenueDemand start */
.venuedemand-low {
    background-color: #5cb189 !important;
}

.venuedemand-medium {
    background-color: #ff0 !important;
}

.venuedemand-high {
    background-color: #f24835 !important;
}

.venuedemand-promotional {
    background-color: #8c8cf4 !important;
}

.month-cal-activity.venue-demand,
.cal-activity.venue-demand {
    border-left: 2px !important;
}

/* VenueDemand End */
.lookup-field-placeholder:empty:before {
    content: attr(data-placeholder);
    color: #858796;
}

/* Page & Grid editor CSS start */

.page-editor .component {
    width: 250px;
    max-width: 350px;
}

.grid-editor .editor-left-sec {
    width: 260px;
    max-width: 350px;
}

.editor-left-sec {
    height: calc(100vh - 130px);
    overflow: auto;
}

.editor-left-sec::-webkit-scrollbar {
    width: 6px;
}

.editor-left-sec::-webkit-scrollbar-thumb {
    background: #c1c1c1;
}

.editor-left-sec::-webkit-scrollbar-thumb:hover {
    background: #888;
}

.grid-editor-list {
    width: calc(100% - 350px);
}

.field-item .btn-group-setting,
.field-item .btn-unmerge-group {
    opacity: 0;
    transition: all .3s;
}

.field-item:hover .btn-group-setting,
.field-item:hover .btn-unmerge-group {
    opacity: 1;
}

.outline-border {
    border: 1px solid #e3e6f0 !important;
}

.grid-editor-sidebar .column-list .field-item:active {
    cursor: grabbing !important;
    background: #c1dcf2 !important;
}

.grid-editor-sidebar .field-list .field-item {
    border-radius: 0.2rem;
}

.grid-editor-sidebar .field-list .field-item:hover {
    color: #3a3b45;
    background-color: #dde2f1;
    border-color: #d4daed;
}

.system-defined-field {
    background: #4f987e !important;
    border-color: #4f987e !important;
    color: white !important;
}

/* Ends */

.text-overflow-ellipsis {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

/* Diary Calendar CSS Start end  */
.font-10 {
    font-size: 10px;
}

.font-12 {
    font-size: 12px;
}

.cursor-pointer:hover {
    cursor: pointer;
}

.date-seek:hover {
    cursor: pointer;
    background-color: #eee;
    /* color: #fff; */
}

.select-sync-button {
    color: #fff;
    cursor: pointer;
}

.diary-calendar-border {
    border-radius: 0%;
    padding: 0px !important;
}

.diary-calendar-background-today-date {
    background-color: #075DA8;
    border-radius: 50%;
    height: 100%;
    padding: 6px 0px;
}

.diary-calendar-background-selected-date {
    background-color: #858796;
    border-radius: 50%;
    height: 100%;
    padding: 6px 0px;
}

/*Start of Style for Day at Diary Component*/
#room-calendar-side-accordion>.card .card-header:first-child,
#room-calendar-side-accordion>.card:first-of-type,
#room-calendar-side-accordion>.card:last-of-type {
    border-radius: 0px;
}

.slot-time {
    min-width: 42px;
    font-size: 11px;
}

.slot-date {
    font-size: 10px;
}

.time-span {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 5;
}

.slots-head-wrapper .time-span {
    // color: #fff;
    color: #075DA8;
    font-weight: 700 !important;
}

.date-wrapper .slot-date {
    position: absolute;
    top: 2px;
    z-index: 5;
}

.date-wrapper .slot-date.today-slot-date {
    left: 30px;
}

.date-wrapper .slot-date.tommorow-slot-date {
    left: 996px;
}

.slots-head-wrapper .slot-date {
    font-weight: bold;
}

.day-event-name {
    position: absolute;
    z-index: 6;
    opacity: 0.6;
    bottom: 0px;
    font-size: 11px;
    background-color: var(--bg-color);
    width: calc(var(--event-size) * 42px);
}

.slot-time:not(.time-slot-selected, .time-slot-selected-before-half, .time-slot-selected-after-half) .day-event-name {
    display: none;
}

.time-slot-selected.slot-time .day-event-name,
.time-slot-selected-before-half.slot-time .day-event-name {
    left: 1px;
}

.time-slot-selected-after-half.slot-time .day-event-name {
    left: calc(50% + 1px);
}

.slots-head-wrapper .slots-col-wrapper {
    // background-color: var(--day-bg-head-color);
    min-height: 50px;
    max-height: 50px;
    border-bottom: 1px solid #fff;
    overflow: hidden;
}

.slot-body-row-wrapper .slots-col-wrapper {
    min-height: 46px;
    max-height: 46px;
    position: relative;
}

.slot-body-row-wrapper .slots-col-wrapper:after {
    content: '';
    position: absolute;
    height: 1px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background-color: #9d9da2;
}

.slot-time .before-half-marker {
    position: absolute;
    bottom: 0px;
    left: 1px;
    width: 38px;
    height: 46px;
    z-index: 5;
}

.slot-time .after-half-marker {
    position: absolute;
    bottom: 0px;
    right: -1px;
    width: 38px;
    height: 46px;
    // z-index: 5;
}

.slots-head-wrapper .slot-time .before-half-marker,
.slots-head-wrapper .slot-time .after-half-marker {
    height: 50px;
}

.slot-time .after-half-marker:after,
.slot-time .before-half-marker:after,
.slot-time .after-half-marker:before,
.slot-time .before-half-marker:before {
    content: '';
    width: 1px;
    position: absolute;
    z-index: 6;
    bottom: 0px;
    display: inline-block;
    background-color: #fff;
}

.slot-body-row-wrapper .slot-time .after-half-marker:after,
.slot-body-row-wrapper .slot-time .before-half-marker:after,
.slot-body-row-wrapper .slot-time .after-half-marker:before,
.slot-body-row-wrapper .slot-time .before-half-marker:before {
    background-color: #9d9da2;
}

.slot-time .before-half-marker:before {
    height: 4px;
    left: 0px;
    display: none;
}

.slot-time .before-half-marker:after {
    height: 6px;
    right: 0px;
    display: none;
}

.slot-time .after-half-marker:before {
    left: 0px;
    height: 6px;
    display: none;
}

.slot-time .after-half-marker:after {
    right: 0px;
    height: 4px;
    display: none;
}

.slot-body-row-wrapper .time-slot-selected-before-half.slot-time .before-half-marker,
.slot-body-row-wrapper .time-slot-selected-after-half.slot-time .after-half-marker,
.slot-body-row-wrapper .time-slot-selected.slot-time .after-half-marker,
.slot-body-row-wrapper .time-slot-selected.slot-time .before-half-marker {
    background-color: var(--time-slot-bg-color);
    opacity: 0.6;
}

.slot-body-row-wrapper .overlap-before.slot-time .before-half-marker {
    background-color: var(--time-slot-bg-overlap-color);
    opacity: 0.6;
}

.slot-body-row-wrapper .overlap-after.slot-time .after-half-marker {
    background-color: var(--time-slot-bg-overlap-color);
    opacity: 0.6;
}

.no-time-on-row.slot-body-row-wrapper .slot-date {
    display: none;
}

.no-time-on-row.slot-body-row-wrapper .time-span {
    display: none;
}

.slots-col-wrapper .slot-time:first-child,
.slots-col-wrapper .slot-time:last-child {
    min-width: 38px !important;
}

.slots-col-wrapper .slot-time:first-child .time-span,
.slots-col-wrapper .slot-time:last-child .time-span {
    display: none;
}

.time-slot-selected.slot-time:first-child .before-half-marker,
.time-slot-selected.slot-time:last-child .after-half-marker {
    display: none;
}

.time-slot-selected.slot-time:first-child .day-event-name,
.time-slot-selected-before-half.slot-time:first-child .day-event-name,
.time-slot-selected-after-half.slot-time:first-child .day-event-name {
    left: 1px;
}

.slots-col-wrapper .slot-time:first-child .after-half-marker:before,
.slots-col-wrapper .slot-time:last-child .before-half-marker:after {
    /* width: 2px; */
    display: none;
}

.font-13 {
    font-size: 13px;
}

.slot-body-row-wrapper:hover .slots-col-wrapper {
    background-color: #075DA8;
}

.slot-body-row-wrapper:hover .slot-time .after-half-marker:after,
.slot-body-row-wrapper:hover .slot-time .before-half-marker:after,
.slot-body-row-wrapper:hover .slot-time .after-half-marker:before,
.slot-body-row-wrapper:hover .slot-time .before-half-marker:before {
    background-color: #fff;
    border-color: #fff;
}

// .slot-body-row-wrapper:hover .slot-date,
// .slot-body-row-wrapper:hover .slot-time {
//     color: #fff;
// }

#room-calendar-side-accordion .room-option {
    min-height: 46px;
    max-height: 46px;
    overflow: hidden;
    position: relative;
    padding: 13px;
}

/* #room-calendar-side-accordion .room-option:first-child{
    min-height: 47px;
    max-height: 47px;
}
#room-calendar-side-accordion .room-option:nth-child(5){
    min-height: 47px;
    max-height: 47px;
} */
#room-calendar-side-accordion .room-option:after {
    content: '';
    position: absolute;
    height: 1px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background-color: #dddfeb;
}

.slot-body-row-wrapper {
    min-height: 46px;
    max-height: 46px;
}

#room-calendar-side-accordion .card {
    border-top: none;
    border-bottom: none;
    min-height: 46px;
    display: block;
}

#room-calendar-side-accordion .card-header {
    /* border: none; */
    min-height: 46px;
    margin-bottom: 0px;
    max-height: 46px;
    overflow: hidden;
    background: rgba(0, 126, 227, 0.07);
}

.accordian-room-heading {
    min-height: 50px;
    max-height: 50px;
    padding: 13px;
    // background: rgba(5, 93, 168 , 30%);
}

/*End of Style for Day Diary Component*/
/*Start Week Style */
.promp-header {
    min-height: 15.5px;
    max-height: 15.5px;
    align-items: center;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
}

.promp-header .week-promp-name {
    padding-right: 10px;
}

.promp-header::-webkit-scrollbar {
    height: 2px;
}

.promp-header::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.promp-header::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}

.slots-wrapper.week-time-slot .head-week-wrapper {
    background-color: var(--day-bg-head-color);
}

.head-week-wrapper:after,
.body-week-wrapper:after {
    position: absolute;
    content: '';
    right: 0px;
    min-width: 1px;
    max-width: 1px;
    background-color: #dddfeb;
    top: 0px;
    bottom: 0px;
}

.head-week-wrapper:after {
    background-color: #fff;
}

.head-week-wrapper,
.body-week-wrapper {
    min-width: 216px;
    max-width: 216px;
}

.body-week-wrapper>.week-hour-mark-wrapper {
    min-height: 45px;
    max-height: 45px;
}

.week-hour-marker {
    min-width: 18px;
    max-width: 18px;
    height: 11px;
    z-index: 2;
}

.week-hour-marker:before {
    content: '';
    min-width: 1px;
    max-width: 1px;
    position: absolute;
    left: 0px;
    z-index: 6;
    bottom: 0px;
    height: 6px;
    display: inline-block;
    // background-color: #fff;
}

.slot-body-row-wrapper .week-hour-marker:before {
    // background-color: #9d9da2;
}

.slot-body-row-wrapper:hover .week-hour-marker:before {
    // background-color: #fff;
}

.week-event-name {
    position: absolute;
    z-index: 6;
    opacity: 0.6;
    bottom: 0px;
    font-size: 11px;
    background-color: var(--bg-color);
    width: calc((var(--event-size) * 18px));
    left: 0px;
}

.event-calander-view{
    max-width:203px !important;
}

/*End Week Style */

/*Start of Common Style for Day and Week*/
.slot-time:not(.time-slot-selected, .time-slot-selected-before-half, .time-slot-selected-after-half) .week-event-name {
    display: none;
}

.time-slot-selected.slot-time .week-event-name,
.time-slot-selected-before-half.slot-time .week-event-name {
    left: 0px;
}

.time-slot-selected-after-half.slot-time .week-event-name {
    left: 50%;
}

.week-hour-mark-wrapper .week-hour-marker:first-child:before {
    display: none;
}

.slot-body-row-wrapper:hover>div {
    background-color: #075DA8;
}

.slot-head-wrapper .head-week-wrapper:last-child:after {
    background-color: #9d9da2;
}

.slot-head-wrapper {
    min-height: 50px;
    max-height: 50px;
}

/*End of Common Style for Day and Week*/
/* Diary Calendar CSS End  */

/* Sidebar Fixing for No Scroll*/
#content-wrapper {
    height: 100vh;
    overflow-y: auto;
}

.sidebar {
    max-height: 100vh;
    min-height: 100vh;
    overflow: initial;
}

#nav-list {
    /* max-height: calc(100vh - 140px); */
    overflow: hidden;
}

.sidebar .nav-item {
    min-height: 42px;
    max-height: 42px;
}

.sidebar .secondry-nav-items .nav-item {
    min-height: 34px;
    max-height: 34px;
}

.sidebar-dark .secondry-nav-items .nav-item .nav-link i {
    font-size: 18px;
}

.sidebar .nav-item:last-child {
    margin-bottom: 0px;
}

#secondry-collapsable-navs-wrapper.collapsing {
    -webkit-transition: none;
    transition: none;
    /* display: none; */
}

#secondry-collapsable-navs {
    overflow-y: auto;
    max-height: 300px;
    height: auto;
    min-height: 75px;
}

@media (max-height: 400px) {
    #secondry-collapsable-navs {
        max-height: calc(100vh - 150px);
        min-height: auto;
    }
}

#secondry-navs .secondry-nav-items {
    position: absolute;
    left: 63px;
    z-index: 997;
    bottom: 0px;
    box-shadow: 0 0.15rem 0.75rem 0 rgba(58, 59, 69, 15%) !important;
    /* top: -300px; */
    min-width: 150px;
    padding-bottom: 45px;
}

.manage-btn-of-secondary-nav {
    position: absolute;
    bottom: 10px;
    background-color: #fff;
    left: 12px;
}

.slide #secondry-navs .secondry-nav-items {
    left: 102%;
}

.secondry-sidebar-collapse-button {
    color: rgba(255, 255, 255, 0.3);
    background: none;
    border: 0;
}

.secondry-sidebar-collapse-button:hover {
    color: rgba(255, 255, 255, 1);
}

.secondry-nav-items .nav-item {
    padding: 0.5rem;
}

.secondry-nav-items .nav-link {
    border-radius: 5px;
}

.secondry-nav-items .nav-item .nav-link {
    padding: 0.35rem 0.5rem;
}

.secondry-nav-items .nav-link:hover,
.secondry-nav-items .nav-item.active .nav-link {
    background-color: #e6eef6;
}

.secondry-nav-items .nav-item .nav-link i {
    color: rgba(6, 93, 168, 0.4);
    height: auto;
}

.sidebar-dark .secondry-nav-items .nav-item span {
    display: inline !important;
    color: #000;
}

.sidebar-dark .secondry-nav-items .nav-item .nav-link:active,
.sidebar-dark .secondry-nav-items .nav-item .nav-link:hover,
.sidebar-dark .secondry-nav-items .nav-item .nav-link:hover i,
.secondry-nav-items .nav-item.active .nav-link,
.secondry-nav-items .nav-item.active .nav-link i,
.sidebar-dark .secondry-nav-items .nav-item .nav-link:active i {
    color: rgb(1, 98, 175);
}

.reseqeance-warning {
    border-radius: 5px;
    background-color: #d2effd;
}

.sidebar~#content-wrapper .fixed-btn-container,
.sidebar~.fixed-btn-container {
    left: 61px;
    z-index: 994;
    width: auto;
    transition: 0.2s left;
}

.slide.sidebar~#content-wrapper .fixed-btn-container,
.slide.sidebar~.fixed-btn-container {
    left: 12.21rem;
    width: auto;
    transition: 0.2s left;
}

/* End of Sidebar Fixing for No Scroll*/
.tab-content .tab-pane .object-detail-right {
    border-left: 1px solid #e3e6f0;
}

.select2-container {
    font-weight: normal;
}

.select2-results__option {
    padding: 3px 6px;
}

.select2-search__field:focus-visible {
    outline: 0px;
}

.select2-selection.select2-selection--single.select2-selection--clearable:focus-visible {
    outline: 0px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__display {
    padding-bottom: 3px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove,
.select2-container--default .select2-selection--multiple .select2-selection__choice {
    background: #fff;
    color: #858796;
    display: inline-flex;
    justify-content: flex-start;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove.hover,
.select2-container--default .select2-selection--multiple .select2-selection__choice:hover {
    background: #858796;
    color: #fff;
}

.select2-selection__choice__remove {
    background-color: transparent !important;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice:hover .select2-selection__choice__remove {
    color: #fff;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
    border-color: #64baff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 126, 227, 25%);
}

/*Suraj changes for select2 border color : Start*/
.select2-dropdown,
.select2-container--default .select2-selection--single,
.select2-container--default .select2-search--dropdown .select2-search__field,
.select2-container--default .select2-selection--multiple,
.select2-container--default .select2-selection--multiple .select2-selection__choice {
    border: 1px solid #d1d3e2;
}

.select2-container--default .select2-selection--single .select2-selection__clear {
    color: #858796;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    border-right: 1px solid #d1d3e2;
}

/*Suraj changes for select2 border color : End*/


.texteditor:focus {
    color: #3a3b45;
    background-color: #fff;
    border-color: #64baff;
    outline: 0;
    box-shadow: 0 0 0 0.15rem rgba(0, 126, 227, 0.25);
}

.iti {
    width: 100%;
}

.iti__country-list {
    z-index: 3;
}

.reseqeance-warning {
    border-radius: 5px;
    background-color: #d2effd;
}

.DashboardLoading {
    position: absolute;
    background: rgba(255, 255, 255, 0.8);
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 9;
}

.DashboardLoading img {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.resources-count {
    color: #075DA8;
}

.resources-count:hover {
    color: #075DA8;
    text-decoration: underline;
}

// /*added by Suraj on 26-May-2022, CF-1732: to fix editor sub script revert issue*/
// sub {
//     vertical-align: sub;
// }

// /*added by Suraj on 26-May-2022, CF-1732: to fix editor super script revert issue*/
// sup {
//     vertical-align: super;
// }

.profile-pic-onboard-container {
    width: 200px;
    border: 1px solid #d1d3e2;
    border-radius: 0.35rem;
    height: 200px;
}

.view-tag-detail-page {
    overflow: hidden;
}

/* Pin/UnPin Menu Option */
#sidebarpinmenu:focus {
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.un-pin-icon {
    height: 2px;
    background: white;
    transform: rotate(40deg);
    position: relative;
    top: -12px;
    width: 18px;
    display: block;
}

/* Pin/UnPin Menu Option */

input:disabled[data-ui="phone"]::-webkit-input-placeholder {
    /* WebKit browsers */
    opacity: 0;
}

/*---List View Css----*/
.listview-container .listview-left-panel {
    width: 230px;
    overflow: auto;
    transition: all .35s;
    height: calc(100vh - 80px);
}

.listview-left-panel-hide .listview-left-panel {
    margin-left: -230px;
}

.listview-display {
    background-color: #EAECF4 !important;
    width: calc(100% - 230px);
    height: calc(100vh - 82px);
    overflow: auto;
}

.listview-display .rounded-pill {
    font-size: 12px;
    font-weight: 500;
}

.listview-left-panel .filter-listing {
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: .875rem;
}

.listview-left-panel .filter-listing li {
    display: block;
    padding: 4px 1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
}

.listview-left-panel .filter-listing li .task-act-btn {
    position: absolute;
    right: 12px;
    top: 2px;
    display: none;
    background: #fff;
}

.listview-left-panel .filter-listing li .task-act-btn.show {
    display: block;
}

.listview-left-panel .filter-listing>li:hover,
.listview-left-panel .filter-listing ul>li:hover,
.listview-left-panel .filter-listing ul>li:hover .nested li:hover {
    color: #075DA7 !important;
}

.listview-left-panel .filter-listing ul>li:hover li,
.listview-left-panel .filter-listing>li:hover .nested li {
    color: #000;
}

.listview-left-panel .filter-listing .lblChk {
    padding-left: 1.25rem;
}

.listview-left-panel .filter-listing .checkmark {
    top: 2px;
}

.listview-left-panel .filter-listing .group-list-caret {
    cursor: pointer;
    -webkit-user-select: none;
    /* Safari 3.1+ */
    -moz-user-select: none;
    /* Firefox 2+ */
    -ms-user-select: none;
    /* IE 10+ */
    user-select: none;
    display: block;
    position: relative;
}

.listview-left-panel .filter-listing .group-list-caret .caret-icon {
    color: #858796;
    position: absolute;
    margin-top: 4px;
    left: -13px;
    opacity: .8;
}

.listview-left-panel .filter-listing .group-list-caret:hover .caret-icon {
    opacity: 1;
}

.listview-left-panel .filter-listing .group-caret-down .caret-icon {
    transform: rotate(90deg);
}

.listview-left-panel .filter-listing .nested {
    display: none;
    padding-left: 0;
}

.listview-left-panel .filter-listing .active {
    display: block;
}

.listview-left-panel .filter-listing li.active {
    background-color: #EAF5FB;
}

.listview-left-panel .filter-listing .text-found {
    background-color: rgba(49, 232, 0, 0.16);
}

.minimize-listview-left-panel {
    background-color: #EAECF4;
    color: #858796;
    width: 2rem;
    height: 2rem;
    padding: 0;
    font-size: 16px;
}

.maximise-listview-left-panel {
    background-color: #fff;
    margin-right: .5rem;
    display: none;
}

.listview-left-panel-hide .maximise-listview-left-panel {
    display: block;
}

.maximise-listview-left-panel i {
    transform: rotate(180deg);
}

.listview-display .listview-buttons .btn-outline-secondary {
    background-color: #fff;
}

.listview-display .listview-buttons .btn-outline-secondary:hover,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show>.btn-outline-secondary.dropdown-toggle {
    background-color: #858796;
}

.group-by-drop .dropdown-menu {
    padding: 0 !important;
}

.group-by-drop .dropdown-toggle::after {
    display: none;
}

.group-by-drop .dropdown-menu .dropdown-item {
    padding-left: 1rem;
    padding-right: 1rem;
    position: relative;
}

.group-by-drop .dropdown-menu .dropdown-item i {
    width: 16px;
    text-align: center;
    font-size: 12px;
    color: #9A9A9A;
}

.group-by-drop .dropdown-menu .dropdown-item .default {
    color: #8A8A8A;
    font-size: 11px;
}

.group-by-drop .dropdown-menu .dropdown-item .pin {
    position: absolute;
    right: 4px;
    top: 4px;
    display: none;
}

.group-by-drop .dropdown-menu .dropdown-item.active,
.group-by-drop .dropdown-menu .dropdown-item:active {
    background: unset;
}

.group-by-drop .dropdown-menu .dropdown-item:hover .pin,
.group-by-drop .dropdown-menu .dropdown-item.active .pin {
    display: block;
}

.group-by-drop .dropdown-menu .dropdown-item.active,
.group-by-drop .dropdown-menu .dropdown-item:hover,
.group-by-drop .dropdown-menu .dropdown-item.active i,
.group-by-drop .dropdown-menu .dropdown-item:hover i {
    color: #075DA8;
}

.listview-display .table thead th {
    border-bottom: 1px;
    border-top: 0 !important;
    padding: 0.35rem 0.5rem;
}

.listview-display .table .head-row .field-text {
    flex-grow: unset !important;
}

.listview-display .table td {
    padding: .35rem .5rem;
}

.listview-display .table td .lblChk {
    margin: 2px 8px 2px 4px;
}

.listview-display .table tr:hover,
.listview-display .table tr.active {
    background-color: #EAF5FB;
}

.listview-display .table tr.head-row:hover {
    background-color: white;
}

.groupby-list-view {
    position: relative;
    min-height: 30px;
}

.groupby-table thead .th {
    max-width: 200px;
}

.groupby-table .main-col {
    min-width: 300px;
}

.groupby-colpase-title {
    position: absolute;
    left: 0;
    top: 5px;
    z-index: 10;
    display: flex;
    align-items: center;
}

.groupby-colpase-title.head-row {
    background: unset;
    color: #075DA8;
}

.groupby-colpase-title .btn {
    height: 26px;
    padding: 3px 10px;
    border-radius: 2px;
    font-weight: 500;
    outline: none;
    box-shadow: none;
    line-height: 1.3;
}

.groupby-colpase-title .groupby-count {
    color: #8A8A8A;
    font-size: 12px;
    margin-left: 0.25rem;
}

.groupby-colpase-title .btn[aria-expanded="false"] i {
    transform: rotate(-90deg);
}

.groupby-colpase-title .btn[aria-expanded="true"] i {
    transform: rotate(0deg);
}

.groupby-colpase-title .btn i {
    color: #8E8E8E;
    transition: all .2s;
}

.priority-dropdown i::after {
    display: none;
}

.priority-dropdown .dropdown-menu {
    min-width: 105px !important;
}

.priority-dropdown .dropdown-menu .dropdown-item {
    padding: 0.25rem 1rem;
}

.groupby-list-action .list-action-btn {
    opacity: 0;
    padding-left: .5rem;
}

.groupby-list-action .list-action-btn .btn {
    padding: .1rem .25rem;
}

.groupby-list-action:hover .list-action-btn {
    opacity: 1;
}

.groupby-table thead {
    position: unset;
}

.msp-data-list .scroll-to-top {
    display: unset;
    line-height: unset;
}

.msp-data-list .groupby-table .head-row th::before {
    height: 1px;
}

.msp-data-list .head-row .expand,
.msp-data-list .head-row .text-wrap {
    margin-left: 4px
}

.msp-data-list .grid-table th[data-field],
.msp-data-list .grid-table th[data-field] .d-flex {
    min-width: unset;
}

.msp-data-list .grid-cell {
    justify-content: unset;
}

.msp-data-list .grid-table tr td:first-child>div.grid-cell {
    padding-left: 0;
}

.listview-display .table td.first {
    padding-left: 0;
}

.listview-display .priority.high {
    color: #F50000;
}

.listview-display .priority.medium {
    color: #FFCC00;
}

.listview-display .priority.low {
    color: #D8D8D8;
}

.listview-display .highlighter.priority.high {
    background-color: #FFCCDD;
    color: #F50000;
}

.listview-display .highlighter.priority.medium {
    background-color: #FFE4CC;
    color: #80644D;
}

.listview-display .highlighter.priority.low {
    background-color: #E0E0E0;
    color: #858585;
}

/* object specfic css */

.listview-container.object-task .listview-display tr.highlighter.overdue {
    background-color: #fcf5f5;
    /* color: #db8c8c; */
}

.listview-container.object-task .listview-display .highlighter.status.to-do {
    background-color: #C5F5F9;
    color: #042B4D;
}

.listview-container.object-task .listview-display .highlighter.status.in-progress {
    background-color: #F9F0BC;
    color: #4D4A3A;
}

.listview-container.object-task .listview-display .highlighter.status.completed {
    background-color: #C1E1C1;
    color: #3D4D3C;
}

.listview-container.object-task .listview-display .highlighter.status.backlog {
    background-color: #B1CDE6;
    color: #627280;
}

.listview-container.object-task .listview-display .highlighter.status.in-design {
    background-color: #FFE4CC;
    color: #80644D;
}

.listview-container.object-task .listview-display .highlighter.status.design-review {
    background-color: #CCDFFF;
    color: #4D5F80;
}

.listview-container.object-task .listview-display .highlighter.status.in-development {
    background-color: #FFCCDD;
    color: #805966;
}

.listview-container.object-task .listview-display .highlighter.status.development-completed {
    background-color: #EEDCF5;
    color: #755980;
}

.listview-container.object-task .listview-display .highlighter.status.in-testing {
    background-color: #E8DDCC;
    color: #807059;
}

.listview-container.object-task .listview-display .highlighter.status.ready-for-live {
    background-color: #dae8bc;
    color: #4c553a;
}

.listview-container.object-task .listview-display .highlighter.status.on-hold {
    background-color: #E0E0E0;
    color: #858585;
}

.listview-container.object-task .listview-display .highlighter.duedate.today {
    background-color: #C1E1C1;
    color: #3D4D3C;
}

.listview-container.object-task .listview-display .highlighter.duedate.past-due-date-overdue {
    background-color: #EEDCF5;
    color: #755980;
}

.listview-container.object-task .listview-display .highlighter.duedate.future {
    background-color: #E8DDCC;
    color: #807059;
}

.listview-container.object-task .listview-display .highlighter.assignedto,
.listview-container.object-task .listview-display .highlighter.assignedto .lookup-display-record {
    background-color: #CCDFFF;
    color: #4D5F80;
}

.listview-container.object-task .listview-display .highlighter.tag,
.listview-container.object-task .listview-display .highlighter.tag .lookup-display-record {
    background-color: #F9F0BC;
    color: #4D4A3A;
}

.listview-display .highlighter.priority.no-priority,
.listview-container.object-task .listview-display .highlighter.status.no-status,
.listview-container.object-task .listview-display .highlighter.duedate.no-due-date,
.listview-container.object-task .listview-display .highlighter.tag.no-tags,
.listview-container.object-task .listview-display .highlighter.assignedto.unassigned {
    background-color: #b7afaf;
    color: #ffffff;
}

/*---End List View Css----*/

ul.miss-records {
    padding-left: 16px;
    margin-bottom: 0;
    font-weight: normal;
}

/*---Suraj Pipeline Guest Room Facilities Start---*/
.chkPipelineGuestRoomFacility {
    padding-left: 25px;
    line-height: 1;
}

.chkPipelineGuestRoomFacilityDetailPage {
    line-height: 1;
    cursor: default;
}

.pipeline-guestroomfacility-displayingrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;
}

/*---Suraj Pipeline Guest Room Facilities End---*/
/*---Suraj Guest Room Facilities Start---*/
.chkGuestRoomFacility {
    padding-left: 25px;
    line-height: 1;
}

.guestroomfacility-displayingrid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 20px;
}

/*---Suraj Guest Room Facilities End---*/

/** lookup field css starts */
.dummy-lookup-format .secondary-label,
[data-field="LookupFormat"] .secondary-label {
    display: block;
    color: #888;
    font-size: 85%;
}

.dummy-lookup-format {
    height: auto;
    min-height: 2.85rem;
    font-size: 0.8125rem;
    background-color: #f8f9fc;
}

/** lookup field css ends */

/* QPC-6095 Changes starts*/
.secondry-sidebar-collapse-button:focus {
    outline: none;
}

/* QPC-6095 Changes ends */

/* QPC-6336 Label color, field color, font-size changes */

.detailPageLabel {
    color: #666 !important;
}

.detailPageFields {
    font-size: 14px !important;
    color: #000 !important;
}

/* QPC-6341 Changes starts*/
#onboard .carousel-item.slide-page-timezone .select2-container--default .select2-results>.select2-results__options {
    max-height: 80px;
    text-align: left;
    color: #000;
}

#onboard .carousel-item.slide-page-timezone .select2 .select2-selection__rendered {
    text-align: left;
    color: #444;
}

/* QPC-6341 Changes end*/
/* Production issue QPC-5473 */
.tooltip {
    pointer-events: none;
}

/* Diary - VenueBooking Start */
.week-venue-booking {
    min-height: 45px;
    max-height: 45px;
    left: var(--week-left);
    background-color: var(--week-bg-color);
    color: var(--week-color);
    font-weight: 600;
    position: absolute;
    z-index: 5;
    min-width: var(--week-width);
    max-width: var(--week-width);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding-top: 12px;
    padding-left: 5px;
    cursor: grab;
    border: 1px solid #fff;
}

.venue-booking-next-date::after {
    content: "\f054";
    right: 0;
    position: absolute;
    font-family: "Font Awesome 5 Free";
}

.day-venue-booking {
    min-height: 45px;
    max-height: 45px;
    left: var(--week-left);
    background-color: var(--week-bg-color);
    color: var(--week-color);
    font-weight: 600;
    position: absolute;
    z-index: 8;
    min-width: var(--week-width);
    max-width: var(--week-width);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding-top: 15px;
    padding-left: 6px;
    cursor: grab;
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
}

/* Diary - VenueBooking Start */

.terms-condition-list-panel {
    height: calc(100vh - 100px);
    overflow-y: auto;
    overflow-x: hidden;
}

.event-ques-seq-table th,
.event-ques-seq-table td {
    white-space: normal !important;
}

.diary-container{
    background:#EAECF4;
    height:calc(100vh - 82px);
    overflow:auto;
    .cal-tabs-sec{
        max-width:100% !important;
        width:100% !important;
        justify-content:space-between;
        display:flex;
        align-items:center;
    }
    .w-250{
        max-width:250px;
        min-width:250px;
    }
    .z-index-1{
        z-index:1;
    }
    .slots-row{
        &:first-child{
            .slot-time {
                border-bottom: 2px solid #E3E6F0;
                // border-right: 1px solid #E3E6F0;
                height:50px;
                .before-half-marker, .after-half-marker{
                    &:hover{
                        background:transparent;
                    }
                }
                &::before{
                    position: absolute;
                    content: "";
                    width: 1px;
                    height: 100%;
                    left: calc(50% - 0.031rem);
                    top: 0px;
                    background: rgb(227, 230, 240);
                }
                &:first-child::before, &:last-child::before{
                    display: none;
                }
            }
        }

        .slot-time {
            min-width: 76px;
            font-size: 12px;
            border-bottom: 1px solid #E3E6F0;
            .before-half-marker, .after-half-marker{
                &:hover{
                    background:#075DA8;
                }
            }
            &:last-child{
                display: none !important;
            }
        }
        &:hover{
            background:none!important;
        }
        &.active{
            .slots-col-wrapper{
                background:#FCF5F5;
            }
            
        }
    }
    .slot-body-row-wrapper:hover > div{
        background:none;
    }

    .accordian-room-heading{
        border-bottom: 2px solid #E3E6F0;
    }
    #room-calendar-side-accordion .card{
        border-left:0;
        border-right:0;
    }
    #room-calendar-side-accordion .card-header{
        background:transparent !important;
       h5{
        font-weight:600;
        color:#47555F;
        &.active{
            color:#075DA8;
        }
       }
       &.active{
           background-color:#FCF5F5 !important;
       }
    }
    .room-option{
        color:#47555F;
    }
    
    .slot-date{
        background-color: #00A1DF !important;
        border-radius:3px;
        top:2px;
        z-index:10 !important;
        line-height: 1.35;
        letter-spacing: .01rem;
    }
    .time-span{
        bottom:7px !important;
    }
    .slots-head-wrapper .slots-col-wrapper{
        border-bottom:0!important;
    }
    .slot-body-row-wrapper .slots-col-wrapper:after{
        display:none;
    }
    .date-wrapper .slot-date.tommorow-slot-date {
        right: -800px;
        left:unset;
    }
    
    .calendar-dropdown {
        max-width: 152px;
        background:#ffffff;
        border-radius:.25rem;
        display:inline-flex;
        align-items:center;
        .date-seek {
            color: #4585BE !important;
        }

        .date-seek:hover {
            background-color: transparent !important;
            color: #075DA8 !important;
        }

        .btn-outline-primary,
        .form-control {
            border-color: white !important;
        }
    
     
    
        .input-group-sm>.form-control:not(textarea) {
            padding: 0.2rem 0.5rem 0.2rem 0;
            font-size: .8rem;
            height: auto !important;
    
        }
        .btn.btn-outline-primary.btn-sm{
            padding-left:0;
            &:hover{
                background:transparent;
                color:#075DA8;
            }
        }
    
        .msp-popup {
            left:-23px !important;
        }
        .form-control.form-control-sm{
            padding-right:0 !important;
        }
    
    }
    .slots-wrapper.week-time-slot .head-week-wrapper{
        background-color:#fff;
        &:after{
            background:#dddfeb !important;
        }
        &:before{
            position: absolute;
            content: '';
            right: 0px;
            height:1px;
            background-color: #dddfeb;
            left: 0px;
            bottom: 0px;
        }
        h6{
            color:#075DA8 !important;
            font-weight:700;
        }
        .promp-header{
            background-color:#00A1DF !important;
        }
    }
    .week-table-header{
        height:50px;
    }
    .setup-teardown-color{
        border: 0px;
        background-image: repeating-linear-gradient(45deg, #eaeff4 0, #eaeff4 2px, transparent 0, transparent 50%);
        background-size: 10px 10px;
        background-color: #f8f9fc !important;
    }
}

// added by komal QPC_6626 for background of selected element of move report Pop-up.
.folder-selected {
    // 
    background: #c1dcf2 !important;
    color: #258391 !important;
}

// QPC-7102, 6616 Dropdown Z-index on Grid
td.grid-col-freeze.active-dropdown {
    z-index: 999 !important;
}

/* Criteria smart search for Field dropdown start */
.smart-search-drop-filter {
    min-width: 300px !important;
}

.smart-search-drop-report {
    min-width: 249.5px !important;
}
/* Criteria smart search for Field dropdown ends */

.eventManagerValue, .accountManagerValue{
    .checkbox{
        white-space:nowrap;
        text-overflow:ellipsis;
        overflow:hidden;
    }
}

/* to add collaborate icon on folder in drive sharewithme */
.shared-folder {
    position: relative;
}

/*Control Panel PMS > Left Side Nav Links start*/
.pmsconfig .nav-link {
    cursor: pointer;
}
/*Control Panel PMS > Left Side Nav Links ends*/

/*survey start */
.svc-page__question-type-selector-icon {
    line-height: normal;
}
.sv-list__filter-icon {
    line-height: normal;
}
.sv-components-row label {
    margin-bottom: auto;
}
.spg-drag-element__svg {
    height: 40px !important;
    width: 40px !important;
}
// for theme
#deviceSelector.sv-action {
    display: none;
}
#orientationSelector.sv-action {
    display: none;
}
#showInvisible.sv-action {
    display: none;
}
#svc-theme-import.sv-action {
    display: none;
}
#svc-theme-export.sv-action {
    display: none;
}
#svc-reset-theme.sv-action {
    display: none;
}
.spg-color-editor__color-swatch{
    margin-top: auto;
}
// end theme
.svc-string-editor, .sv-string-viewer {
    white-space: normal;
}
.sd-boolean__thumb-text .sv-string-editor,
.sd-boolean__thumb-text .sv-string-viewer {
    white-space: nowrap;
}
.survey-chart-continer {
    position: relative;
}
.survey-chart {
    width: 75%;
    height: 100%;
    position: absolute;
}
.sd-row--multiple .svc-question__content--fullname .svc-row .sd-row.sd-row--multiple {
    flex-wrap: wrap;
}
/*survey end */
.pms-availability{
 background-color:#EAECF4 !important;
 height:calc(100vh - 81px);
 overflow:auto;
}

.pms-availability {
    background-color: #EAECF4 !important;
    height: calc(100vh - 81px);
    overflow: auto;
}

/*PMS availability*/
.pms-availability>.card-header {
    background: transparent !important;
}

.calendar-dropdown {
    max-width: 152px;

    .date-seek {
        color: #4585BE !important;
    }

    .date-seek:hover {
        background-color: transparent !important;
        color: #075DA8 !important;
    }

    .btn-outline-primary,
    .form-control {
        border-color: white !important;
    }

    .input-group-sm>.form-control:not(textarea) {
        padding: 0.4rem 0.5rem 0.4rem 0;
        font-size: .875rem;
        height: auto !important;
    }

    .msp-popup {
        right: -15px !important;
        width: 200px;
        left: unset !important;
    }
}

.pms-rooms-sec {
    max-width: 210px;
    width: 100%;
    box-shadow: 4px 0 13px rgba(0, 0, 0, .09);
    position: relative;
    z-index: 1;

    .room-name-h {
        display: flex;
        padding: 20px 15px;
        height: 97px;
        align-items: center;
        font-size: 18px;
        color: #075DA8;
        font-weight: 500;
    }

    ul {
        display: block;
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            border-top: 1px solid #E3E6F0;
            line-height: 1.25;
            height: 48px;
            display: flex;
            align-items: center;
            padding: 0 15px;

            div {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}

.pms-rooms-val {
    overflow: auto;

    .row {
        margin: 0;
        flex-wrap: nowrap;

        &:last-child {
            .col {
                border-bottom: 0;

                &:last-child {
                    border-radius: 0 0 .35rem 0;
                }

            }
        }

        &:first-child {
            .col {
                &:last-child {
                    border-radius: 0 .35rem 0 0;
                }
            }
        }

        .col {
            &:last-child {
                border-right: 0;
            }
        }


    }

    .col {
        border: 1px solid #fff;
        border-top: 0;
        border-left: 0;
        height: 48px;
        align-items: center;
        justify-content: center;
        display: flex;
        background-color: #E5F6DF;
        font-weight: 500;
        padding: 0 20px;
        min-width: 77px;
    }

    .col.not-available {
        background-color: #FBD9D3;
    }

    .header {
        .col {
            border-color: #eee;
            height: unset;
            background-color: white;
            flex-direction: column;
            padding: 20px;
        }

        .date {
            width: 2.25rem;
            height: 2.25rem;
            margin: auto;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            line-height: 1.2;
            font-weight: 500;

            span {
                padding-bottom: 2px;
            }
        }

        .week-view-head {
            font-size: .875rem;
            text-transform: uppercase;
        }
    }
}
/*For selection2 customize options which may have long value start*/
.select2-customize-output{
    max-width:100%;
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
    display:block;
}
/*For selection2 customize options which may have long value end*/
/* added by Neeraj for some Quill Text Editor CSS Changes for story QPC 7463*/
.ql-container {
    height: 150px;
    border-radius: 0 0 0.2rem 0.2rem!important;
}

.ql-toolbar.ql-snow{
    background-color: #f8f9fc;
    border-radius:0.2rem 0.2rem 0 0;
}

.ql-toolbar.ql-snow, .ql-container.ql-snow {
    border: 1px solid #d1d3e2;
}

.ql-toolbar.ql-snow .ql-picker-label{
    overflow: hidden;
}

.ql-snow.ql-toolbar button{
    text-align: center;
    width: 30px;
}

.ql-toolbar.ql-snow .ql-formats {
    margin-right: 0px;
}

.ql-tooltip {
  left: 0 !important;
}

.ql-snow .ql-picker {
    z-index: 1000;
}

.ql-snow .ql-tooltip a.ql-action::after {
    margin-left: 0px;
    color: #fff;
    border-right : 0px;
    padding-right: 0px;
}

.ql-snow .ql-tooltip a.ql-remove::before {
    margin-left: 0px;
}

.ql-snow .ql-tooltip a {
    line-height: 1.415;
}

.ql-save{
    background-color: #075DA8;
    color: #fff;
    margin-left: 10px;
    border-radius: 0.35rem;
}

.ql-remove{
    margin-left: 5px;
    background: #d1d3e2;
    border-color: #d1d3e2;
    border-radius: 0.35rem;
}

.editor-container{
    z-index:1;
    position:relative;
}
/* added by Neeraj for some Quill Text Editor CSS Changes for story QPC 7463*/

.ql-comment-section{
    z-index:0;
    max-width: calc(100% - 45px);
}

.ql-edit-comment{
    max-width: 45px;
}

.ql-snow .ql-tooltip a.ql-preview {
    vertical-align: middle;
}

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
    max-height: 180px;
    overflow: auto;
}

.editor-text p {
    margin-bottom: 0;
}
.ql-align-center {
    text-align: center;
    list-style-position: inside;
}
.ql-align-right {
    text-align: right;
    list-style-position: inside;
}
.ql-align-left {
    text-align: left;
    list-style-position: inside;
}
.ql-indent-1 {
    padding-left: 3em;
    list-style-position: inside;
}
.ql-indent-2 {
    padding-left: 6em;  
    list-style-position: inside;
}
.ql-indent-3 {
    padding-left: 9em; 
    list-style-position: inside;
}
.ql-indent-4 {
    padding-left: 12em;
    list-style-position: inside;
}
.ql-indent-5 {
    padding-left: 15em;
    list-style-position: inside;
}
.ql-indent-6 {
    padding-left: 18em;  
    list-style-position: inside;
}
.ql-indent-7 {
    padding-left: 21em;  
    list-style-position: inside;
}
.ql-indent-8 {
    padding-left: 24em;  
    list-style-position: inside;
}

/* added by Neeraj for some Quill Text Editor CSS Changes for story QPC 7463*/

/*Control Panel PMS > Left Side Nav Links ends*/
/*For selection2 customize options which may have long value start*/
.select2-customize-output{
    max-width:100%;
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
    display:block;
}
/*For selection2 customize options which may have long value end*/

/*otp screen css starts*/
.otpScreen .modalDialog {
    min-width: 500px !important;
}
/*otp screen css ends*/